import React from 'react';
import PropTypes from 'prop-types';
import IconCash from './icons/Cash';
import IconCard from './icons/Card';
import s from './PaymentMethod.module.css';

const types = {
  by_cash: {
    icon: IconCash,
    label: 'Оплата наличными курьеру',
  },
  by_card: {
    icon: IconCard,
    label: 'Банковской картой курьеру',
  },
  online: {
    icon: IconCard,
    label: 'Банковской картой через сайт',
  },
  deposit: {
    icon: IconCash,
    label: 'Депозит',
  },
};

const PaymentMethod = ({ className, checked, type, ...props }) => (
  <div styleName={checked ? 'checked' : 'normal'} className={className} {...props}>
    {React.createElement(types[type].icon, {
      className: type === 'online' || type === 'by_card' ? s.ecashIcon : s.icon,
    })}
    <div styleName="text">{types[type].label}</div>
  </div>
);

export const paymentMethods = Object.keys(types);

PaymentMethod.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(paymentMethods).isRequired,
};

PaymentMethod.defaultProps = {
  className: undefined,
};

export default PaymentMethod;
