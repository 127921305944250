import React from 'react';
import formatDate from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import cn from 'classnames';
import { Link } from 'gatsby';
import { useQuery } from 'urql';
import { get } from 'unchanged';
import Loading from './Loading';

import Price from './Price';
import './MyDepositTab.module.css';

const query = `
  query MyDepositQuery {
    session { customer {
      depositTransactions {
        orderId
        value
        stepValue
        createdAt
      }
    } }
  }
`;

const getLabel = ({ orderId, value }) => {
  if (orderId) {
    return `Заказ №${orderId}`;
  }

  if (value > 0) {
    return 'Пополнение';
  }

  return 'Заказ';
};

const MyDepositTab = () => {
  const [res] = useQuery({ query });
  const transactions = get('session.customer.depositTransactions', res.data) || [];

  if (res.error) {
    return (
      <div styleName="root">Произошла ошибка. Мы отслеживаем каждую ошибку и скоро все исправим. Попробуйте позже.</div>
    );
  }

  return (
    <div styleName="root">
      {res.fetching && <Loading />}
      {transactions.length > 0 ? (
        <table styleName="table">
          <thead>
            <tr>
              <th>Событие</th>
              <th styleName="number">Дата</th>
              <th styleName="number">Сумма</th>
              <th styleName="number">Осталось</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((item, i) => (
              <tr key={item.orderId || i}>
                <td>{getLabel(item)}</td>
                <td styleName="number">{formatDate(item.createdAt, 'dddd D MMMM, H:mm', { locale })}</td>
                <td styleName="number">
                  <Price value={Number(item.value) || 100} />
                </td>
                <td styleName={cn('number', { current: i === 0 })}>
                  <Price value={Number(item.stepValue) || 200} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <div>История депозита не найдена.</div>
          <Link to="/deposit">Узнать больше про депозит</Link>
        </>
      )}
    </div>
  );
};

export default MyDepositTab;
