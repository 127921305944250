import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'components/Analytics';
import * as Sentry from '@sentry/browser';
import qs from 'query-string';

export const ModalContext = React.createContext();

const initialQuery = typeof window !== 'undefined' ? qs.parse(window.location.search) : {};

const getParams = data => {
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return null;
};

const ModalRenderer = ({ modals, children, ...props }) => {
  const [query, setQueryValue] = useState(initialQuery);
  const openedModals = Object.keys(query).filter(k => k.match(/Modal$/) && query[k] !== undefined);

  const setQuery = q => {
    setQueryValue(q);
    window.history.pushState(null, null, `${window.location.pathname}?${qs.stringify(q)}`);
  };

  const value = {
    query,
    setQuery,
    openModal: (key, params = null) => {
      analytics.openModal(key);
      setQuery({ [`${key}Modal`]: JSON.stringify(params) });
    },
    closeModal: key => setQuery({ ...query, [`${key}Modal`]: undefined }),
  };

  useEffect(() => {
    const fn = () => {
      const data = qs.parse(window.location.search);

      Object.keys(data).forEach(k => {
        if (data[k] && k.match(/Modal$/) && data[k] !== 'null') {
          data[k] = getParams(data[k]);
        }
      });

      setQueryValue(data);
    };

    window.addEventListener('popstate', fn);
    return () => window.removeEventListener('popstate', fn);
  }, []);

  useEffect(() => {
    if (openedModals.length > 0) {
      /* eslint-disable no-return-assign */
      const old = document.documentElement.style.overflow;
      document.documentElement.style.overflow = 'hidden';
      return () => (document.documentElement.style.overflow = old);
    }

    return undefined;
  }, [openedModals]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {openedModals.map(m => {
        const key = m.replace(/Modal$/, '');
        const Modal = modals && modals[key];

        if (!Modal) {
          return null;
        }

        const onClose = () => {
          setQuery({
            ...query,
            [m]: undefined,
          });
        };

        return (
          <Modal
            mob={window.location.pathname.indexOf('/mob') === 0}
            key={key}
            onClose={onClose}
            openModal={value.openModal}
            {...getParams(query[m])}
            {...props}
          />
        );
      })}
    </ModalContext.Provider>
  );
};

ModalRenderer.propTypes = {
  modals: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default ModalRenderer;
