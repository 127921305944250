import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import cn from 'classnames';
import { goods } from 'utils/format';
import Repeat from 'components/icons/Repeat';
import { useGlobal } from './hooks';
import './OrderCard.module.css';

const statuses = {
  canceled: 'Отменен',
  new: 'Собирается',
  processing: 'Доставлен',
  complete: 'Выполнен',
};

const OrderCard = ({ data, openModal, ...props }) => (
  <div styleName="account-order" title={`Заказ №${data.entity_id}`} {...props}>
    <div styleName="button-overlay">
      <Button openModal={openModal} data={data} />
    </div>
    <div styleName="cover-img">
      <Collage items={data.items.filter(i => i.product)} />
    </div>
    <h5 styleName="date">{formatDate(data.delivered_time, 'dddd D MMMM, H:mm', { locale })}</h5>
    <p styleName="items-quantity">{goods(data.items.length)}</p>
    <div styleName="bottom">
      <p styleName="price">{`${parseFloat(data.subtotal || '0').toFixed(2)} р`}</p>
      <div styleName={cn('status', data.state === 'canceled' ? 'statusCanceled' : 'statusSuccess')}>
        {statuses[data.state] || data.state}
      </div>
    </div>
  </div>
);

const getImage = item => <img styleName="img" src={item.product.imageUrl} alt="" />;

const getRow = (items, index) => (
  <React.Fragment>
    <div styleName="cover-img-2">{getImage(items[index])}</div>
    <div styleName="cover-img-2">{getImage(items[index + 1])}</div>
  </React.Fragment>
);

const Collage = ({ items }) => {
  if (items.length > 3) {
    return (
      <React.Fragment>
        <div styleName="cover-img-3">{getRow(items, 0)}</div>
        <div styleName="cover-img-3">{getRow(items, 2)}</div>
      </React.Fragment>
    );
  }

  if (items.length > 2) {
    return (
      <React.Fragment>
        <div styleName="cover-img-3">{getRow(items, 0)}</div>
        <div styleName="cover-img-3">{getImage(items[2])}</div>
      </React.Fragment>
    );
  }

  if (items.length > 1) {
    return getRow(items, 0);
  }

  if (items.length > 0) {
    return getImage(items[0]);
  }

  return null;
};

Collage.propTypes = {
  items: PropTypes.array.isRequired,
};

const Button = ({ data, openModal }) => {
  const [, addMultiple] = useGlobal(
    () => null,
    actions => actions.cart.addMultiple
  );

  if (data.old) {
    const repeat = () => {
      addMultiple(data.items);
      openModal('cart');
    };

    return (
      <button type="button" styleName="order-button" onClick={repeat}>
        <Repeat styleName="repeat-icon" size={50} />
        <p styleName="repeat-text">Повторить заказ</p>
      </button>
    );
  }

  const paymentUrl = `https://moscowfresh.ru/pay/${data.entity_id}`;

  return (
    <a styleName="order-link" href={paymentUrl} target="_blank" rel="noopener noreferrer">
      <div styleName="epay-button">Оплатить заказ картой</div>
    </a>
  );
};

Button.propTypes = {
  data: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
};

OrderCard.propTypes = {
  data: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default OrderCard;
