import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { connect, withModal, compose } from 'components/hocs';
import { selectors } from 'store';
import Analytics from 'components/Analytics';
import ModalLink from 'components/ModalLink';
import MiniCart from 'components/MiniCart';
import CartProduct, { GiftCardProduct } from 'components/CartProduct';
import IconArrow from 'components/icons/Arrow';
import { rubles } from 'utils/format';
import './Cart.module.css';

const getTitleMessage = ({ itemSum, minItemSum, freeDeliveryItemSum }) => {
  if (minItemSum > itemSum) {
    return `Минимальная стоимость заказа - ${rubles(
      minItemSum
    )}. Чтобы отправить заказ, добавьте продукты ещё на ${rubles(minItemSum - itemSum)}`;
  }

  if (itemSum > freeDeliveryItemSum) {
    return 'По каждому заказу мы вручную выбираем и упаковываем самые свежие продукты. + доставка в пределах МКАД в подарок!';
  }

  const edge = freeDeliveryItemSum - itemSum;
  if (edge > 0) {
    return `Добавьте еще на ${edge} рублей и получите доставку в подарок!`;
  }

  return 'По каждому заказу мы вручную выбираем и упаковываем самые свежие продукты.';
};

const getBottomButton = (itemSum, minItemSum, onClose, mob) => {
  if (!itemSum) {
    return (
      <button onClick={onClose} styleName="button" type="button">
        Продолжить покупки
      </button>
    );
  }

  if (minItemSum > itemSum) {
    return (
      <button onClick={onClose} styleName="button" type="button">
        {mob ? 'Добавить ещё что-то вкусное ...' : 'Добавить ещё что-то вкусное до минимального заказа'}
      </button>
    );
  }

  return (
    <ModalLink single to="checkout" component="button" type="button" styleName="button">
      Указать адрес доставки
      <span styleName="arrow-wrapper">
        <IconArrow styleName="button-arrow" color="mini-white" />
        <IconArrow styleName="button-arrow" color="mini-orange" />
      </span>
    </ModalLink>
  );
};

const Header = ({ products, onClose, itemSum, minItemSum, freeDeliveryItemSum, mob, globalActions, hint }) =>
  mob ? (
    <div>
      <div styleName="header">
        <button type="button" onClick={onClose} styleName="mob-close">
          <IconArrow color="mini-orange" styleName="header-icon" />
        </button>
        <MiniCart styleName="mini-cart" />
      </div>
      {hint && <div styleName="hint">{hint}</div>}
      <div styleName="mobile-panel">
        <div styleName="mobile-title">Корзина</div>
        {products.length > 0 && (
          <button
            type="button"
            onClick={() => {
              globalActions.cart.clear();
              onClose();
            }}
            styleName="mobile-title clear-cart"
          >
            Очистить корзину
          </button>
        )}
      </div>
    </div>
  ) : (
    <div styleName="header">
      {products.length > 0 && (
        <button
          type="button"
          onClick={() => {
            globalActions.cart.clear();
            onClose();
          }}
          styleName="clear"
        >
          Очистить корзину
        </button>
      )}
      <div styleName="header-content">
        <div styleName="title">{itemSum ? `Продуктов в корзине на ${rubles(itemSum, true)}.` : 'Корзина пуста'}</div>
        {products.length > 0 && (
          <div styleName="title-message">{getTitleMessage({ itemSum, minItemSum, freeDeliveryItemSum })}</div>
        )}
        {hint && <div styleName="hint">{hint}</div>}
      </div>
      <div styleName="header-action">
        {itemSum > minItemSum && (
          <ModalLink single to="checkout" component="button" type="button" styleName="button">
            Указать адрес доставки
            <span styleName="arrow-wrapper">
              <IconArrow styleName="button-arrow" color="mini-white" />
              <IconArrow styleName="button-arrow" color="mini-orange" />
            </span>
          </ModalLink>
        )}
      </div>
    </div>
  );

Header.propTypes = {
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  minItemSum: PropTypes.number.isRequired,
  freeDeliveryItemSum: PropTypes.number.isRequired,
  itemSum: PropTypes.number.isRequired,
  mob: PropTypes.bool.isRequired,
  globalActions: PropTypes.object.isRequired,
  hint: PropTypes.string,
};

Header.defaultProps = {
  hint: '',
};

// пустые контейнеры, чтобы прижать карточки к левому краю
const placeholders = [];

for (let i = 0; i < 5; i += 1) {
  placeholders.push(<div key={i} style={{ width: 192 }} />);
}

const Cart = ({
  onClose,
  products,
  mob,
  globalActions,
  minItemSum,
  freeDeliveryItemSum,
  itemSum,
  quantityById,
  giftProducts,
  hint,
}) => (
  <div styleName="root" id="modal-cart">
    <Header
      mob={mob}
      products={products}
      onClose={onClose}
      itemSum={itemSum}
      minItemSum={minItemSum}
      freeDeliveryItemSum={freeDeliveryItemSum}
      globalActions={globalActions}
      hint={hint}
    />
    <div styleName="content">
      {mob && <div styleName="mobile-description">{getTitleMessage({ itemSum, minItemSum, freeDeliveryItemSum })}</div>}
      {products.length > 0 || giftProducts.length > 0 ? (
        <React.Fragment>
          <Analytics
            type="viewBasket"
            itemSum={itemSum}
            items={products.map(p => ({
              id: p.entity_id,
              price: p.price,
              quantity: quantityById[p.entity_id],
            }))}
          />
          {giftProducts.map(product => (
            <GiftCardProduct mob={mob} key={product.entity_id} data={product} />
          ))}
          {products.map(product => (
            <CartProduct
              mob={mob}
              key={product.entity_id}
              data={product}
              onRemove={() => globalActions.cart.remove(product, 0)}
            />
          ))}
          {placeholders}
        </React.Fragment>
      ) : (
        <div styleName="empty-text">Добавляйте продукты в корзину и они появятся тут</div>
      )}
    </div>
    <div styleName="footer">{getBottomButton(itemSum, minItemSum, onClose, mob)}</div>
  </div>
);

Cart.propTypes = {
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  giftProducts: PropTypes.array.isRequired,
  minItemSum: PropTypes.number.isRequired,
  freeDeliveryItemSum: PropTypes.number.isRequired,
  itemSum: PropTypes.number.isRequired,
  quantityById: PropTypes.object.isRequired,
  globalActions: PropTypes.object.isRequired,
  mob: PropTypes.bool,
  hint: PropTypes.string,
};

Cart.defaultProps = {
  mob: undefined,
  hint: null,
};

// TODO: add logic
const selector = state => ({
  ...state.cart,
  minItemSum: 2500,
  freeDeliveryItemSum: 5000,
  itemSum: selectors.getItemSum(state),
  hint: selectors.getCartHint(state),
});

const enhance = compose(
  withModal({
    maxWidth: 1050,
  }),
  connect(selector)
);

const EnhancedCart = enhance(Cart);

export default props => (
  <StaticQuery
    query={graphql`
      query CardModalQuery {
        magento {
          cachedCatalog {
            giftProducts {
              entity_id
              name
              imageUrl: mainImage(width: 285)
            }
          }
        }
      }
    `}
    render={data => <EnhancedCart {...props} giftProducts={data.magento.cachedCatalog.giftProducts} />}
  />
);
