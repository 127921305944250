// import smoothscroll from 'smoothscroll-polyfill';
import { init } from '@sentry/browser';
import wrapPageElement from './src/wrapPageElement';

init({
  dsn: 'https://e6f4a513801e4df980f7adf09b6a6c5b@sentry.io/1407591',
});

// if (typeof window !== 'undefined') {
//   smoothscroll.polyfill();
// }

// eslint-disable-next-line import/prefer-default-export
export { wrapPageElement };

export const onRouteUpdate = ({ location }) => {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof window.ym !== `undefined`) {
    window.ym(33509038, 'hit', (location || {}).pathname);
  }
};

export const onClientEntry = () => {
  if (window.innerWidth <= 666) {
    let search = '';
    if (window.location.search) {
      search = `?${window.location.search}`;
    }
    // тут 404, но это залогируют access logs
    fetch('/jsdetectmobile').then(() => {
      window.location.href = `https://m.moscowfresh.ru/${window.location.pathname}${search}`;
    });
  }
};
