import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import svg from './pear.svg';
import './Pear.module.css';

const PearIcon = ({ className, ...props }) => <Icon styleName="root" className={className} src={svg} {...props} />;

PearIcon.propTypes = {
  className: PropTypes.string,
};
PearIcon.defaultProps = {
  className: undefined,
};

export default PearIcon;
