import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import locale from 'date-fns/locale/ru';
import { capitalize } from 'utils/strings';

import DeliveryDateDropdown from 'components/DeliveryDateDropdown';
import DeliveryTimeDropdown from 'components/DeliveryTimeDropdown';

import './CheckoutForm.module.css';

const get2Digit = value => (value < 10 && value >= 0 ? `0${value}` : value);
const formatTime = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return `${get2Digit(h)}:${get2Digit(m)}`;
};

const DeliveryTimeInput = ({ value, onChange, deliveryIntervals }) => {
  const [date, time] = value.split('T');

  const availableDays = deliveryIntervals
    .reduce((list, i) => {
      if (list.indexOf(i.date) === -1) {
        list.push(i.date);
      }
      return list;
    }, [])
    .map(d => ({
      value: d,
      label: capitalize(format(d, 'dd, D MMM', { locale })),
    }));

  const timeOptions = deliveryIntervals
    .filter(i => i.date === date)
    .map(i => ({
      disabled: false,
      value: formatTime(i.from),
      label: `${formatTime(i.from)} - ${formatTime(i.to)}`,
    }));

  // const availableDays = rawAvailableDays.filter(hasTimeOptions);

  // Блок коррекции времени
  useEffect(() => {
    if (timeOptions.length > 0) {
      const timeOption = timeOptions.find(o => o.value === time && !o.disabled);

      // если выбранное время недоступно, то меняем на первое
      if (!timeOption) {
        const firstOption = timeOptions.find(o => !o.disabled);

        if (firstOption) {
          onChange(`${date}T${firstOption.value}`);
        }
      }
    }
  }, [time, timeOptions]);

  // Блок коррекции даты
  useEffect(() => {
    if (availableDays.length > 0) {
      const day = availableDays.find(d => d.value === date);

      // если выбранная дата недоступна, то меняем на первую
      if (!day) {
        onChange(`${availableDays[0].value}T${time}`);
      }
    }
  }, [date, availableDays]);

  const dateOnChange = useCallback(item => onChange(`${item.value}T${time}`), [time]);
  const timeOnChange = useCallback(v => onChange(`${date}T${v}`), [date]);

  return (
    <div styleName="row">
      <div styleName="cell">
        <DeliveryDateDropdown styleName="date-dropdown" options={availableDays} value={date} onChange={dateOnChange} />
      </div>
      <div styleName="cell">
        <DeliveryTimeDropdown styleName="time-dropdown" options={timeOptions} value={time} onChange={timeOnChange} />
      </div>
    </div>
  );
};

DeliveryTimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  deliveryIntervals: PropTypes.array.isRequired,
};

DeliveryTimeInput.defaultProps = {
  value: '',
};

export default DeliveryTimeInput;
