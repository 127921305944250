import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import cn from 'classnames';
import './DeliveryDateDropdown.css';

const DeliveryDateDropdown = ({ className, ...props }) => (
  <Dropdown placeholder="Дата доставки" className={cn(className, 'delivery-date-dropdown')} {...props} />
);

DeliveryDateDropdown.propTypes = {
  className: PropTypes.string,
};

DeliveryDateDropdown.defaultProps = {
  className: undefined,
};

export default DeliveryDateDropdown;
