export default `
  query PersistStateQuery($addedIds: [Int!]!) {
    enabledFeatures
    cartProducts: products(ids: $addedIds) {
      entity_id
      name
      imageUrl: mainImage(width: 285)
      retinaImageUrl: mainImage(width: 570)
      description
      weight
      fresh_start_item
      price
      finalPrice
      season {
        color
        text
      }
      text_badge
      is_link_more_info
      url_path
      detox
      eco
      heavy
      raw
      shelf_life_date
      shelf_life_required
      manufacture_date
      qty
      warehouse_qty
      averageConsumption
      fresh_count_item
      fresh_type_unit
      freshType
    }

    session {
      SID
      isLoggedIn
      customer {
        haveSuccessfulOrder
        totalOrders

        bonuses {
          total
        }

        info {
          name
          lastname
          email
          phone
          comment
          address
          city
        }
      }
    }

    settings {
      businessHours {
        today
        todayTime
        timeStopTakingToday
        defaultDeliveryLatency
        blackListDay
        blackListHours
        isEcoStore

        showAsap {
          enable
          show_from
          show_until
        }
      }
    }
  }
`;
