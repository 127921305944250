import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Footer from './Footer';
import FadeImage from '../FadeImage';
import s from './CartProduct.module.css';

const ProductCard = ({ mob, className, data, onRemove }) => (
  <div styleName={cn('root', { mob })} data-cy="cart-product" className={className}>
    <div styleName="image-wrapper">
      <FadeImage styleName="image" alt={data.name} src={data.imageUrl} />
      {onRemove && <button type="button" styleName="close" onClick={onRemove} />}
    </div>
    <div styleName="description">
      <div styleName="title-wrapper">
        <h5 styleName="title">{data.name}</h5>
      </div>
      <Footer fakeDropDownClassName={s.productDropdown} data={data} />
    </div>
  </div>
);

ProductCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
  mob: PropTypes.bool,
};

ProductCard.defaultProps = {
  className: undefined,
  onRemove: undefined,
  mob: false,
};

export const GiftCardProduct = ({ mob, data, className }) => (
  <div styleName={cn('root', { mob })} data-cy="cart-product" className={className}>
    <div styleName="image-wrapper">
      <FadeImage styleName="image" alt={data.name} src={data.imageUrl} />
    </div>
    <div styleName="description">
      <div styleName="title-wrapper">
        <h5 styleName="title">{data.name}</h5>
      </div>
      <div styleName="footer">
        <div styleName="footer-left">1 шт</div>
        <div styleName="footer-right">Подарок</div>
      </div>
    </div>
  </div>
);

GiftCardProduct.propTypes = {
  data: PropTypes.object.isRequired,
  mob: PropTypes.bool,
  className: PropTypes.string,
};

GiftCardProduct.defaultProps = {
  mob: false,
  className: undefined,
};

export default ProductCard;
