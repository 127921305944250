import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Form } from 'react-final-form';
import { useMutation } from 'urql';
import { get } from 'unchanged';
import PhoneInput from 'components/PhoneInput';
import Input from 'components/Input';
import Button from 'components/Button';
import { validatePhone, validateName } from '../utils/validators';

import './MyPersonalTab.module.css';

const mutation = `
  mutation($name: String, $phone: String, $address: String, $comment: String) {
    customer {
      changeInformation(name: $name, phone: $phone, address: $address, comment: $comment) {
        SID
      }
    }
  }
`;

const identity = x => x;

const MyPersonalTab = ({ viewer, mob }) => {
  const [res, executeMutation] = useMutation(mutation);

  const initialValues = {
    ...viewer.info,
    phone: viewer.info.phone ? viewer.info.phone.replace(/[^0-9]/g, '').replace(/^7/, '') : '',
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={executeMutation}
      render={({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} styleName={cn('form', { mob })}>
          <h3 styleName="form-title">Личная информация</h3>
          <div styleName="form-grid">
            <div styleName="form-col">
              <Input label="Имя" placeholder="Имя" name="name" validate={validateName} />
            </div>
            <div styleName="form-col form-col_phone">
              <PhoneInput
                name="phone"
                label="Контактный телефон"
                placeholder="Контактный телефон"
                type="text"
                validate={validatePhone}
              />
            </div>
          </div>
          <div styleName="form-address">
            <Input
              name="address"
              label="Адрес доставки и стандартный комментарий к заказу"
              placeholder="Адрес доставки и стандартный комментарий к заказу"
              type="text"
              parse={identity}
            />
          </div>
          <Input
            name="comment"
            label="Комментарий к заказу / промокод"
            placeholder="Комментарий к заказу / промокод"
            cols="30"
            rows="5"
            parse={identity}
            component="textarea"
          />
          <div styleName="footer">
            <Button disabled={submitting} type="submit">
              Сохранить
            </Button>
            <div styleName="success-message">
              {get('data.customer.changeInformation.SID', res) && 'Персональные данные успешно обновлены'}
            </div>
          </div>
        </form>
      )}
    />
  );
};

MyPersonalTab.propTypes = {
  viewer: PropTypes.object.isRequired,
  mob: PropTypes.bool,
};

MyPersonalTab.defaultProps = {
  mob: false,
};

export default MyPersonalTab;
