import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Button.module.css';

const Button = ({ className, color, disabled, children, onClick, ...props }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    styleName={cn('root', disabled ? 'disabled' : color)}
    className={className}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  color: 'orange',
  disabled: false,
  className: undefined,
  children: undefined,
  onClick: undefined,
};

export default Button;
