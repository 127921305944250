import nanoid from 'nanoid';

const memoryStorage = {};
const windowIdKey = 'lastEmitterId';
const windowId = nanoid();

export const setItem = (key, value) => {
  memoryStorage[key] = value;

  try {
    localStorage.setItem(windowIdKey, windowId);
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
};

export const getItem = key => {
  if (typeof window === 'undefined') {
    return null;
  }

  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return memoryStorage[key] || null;
  }
};

export const removeItem = key => {
  delete memoryStorage[key];

  try {
    localStorage.removeItem(key);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
};

export const subscribe = (keys, callback) => {
  try {
    const fn = e => {
      if (keys.indexOf(e.key) > -1 && getItem(windowIdKey) !== windowId) {
        callback(e.key, JSON.parse(e.newValue), JSON.parse(e.oldValue), e);
      }
    };

    window.addEventListener('storage', fn);

    return () => window.removeEventListener('storage', fn);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return () => {};
  }
};
