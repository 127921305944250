import React from 'react';
import PropTypes from 'prop-types';
import './HintDot.module.css';

const HintDot = ({ children, ...props }) => (
  <span data-rt={children} styleName="root" {...props}>
    ?
  </span>
);

HintDot.propTypes = {
  children: PropTypes.string.isRequired,
};

export default HintDot;
