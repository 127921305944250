import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import svg from './ok.svg';
import './Ok.module.css';

const OkIcon = ({ className, ...props }) => <Icon styleName="root" className={className} src={svg} {...props} />;

OkIcon.propTypes = {
  className: PropTypes.string,
};
OkIcon.defaultProps = {
  className: undefined,
};

export default OkIcon;
