import { useRef, useEffect } from 'react';
import { useQuery } from 'urql';
import * as storage from 'utils/storage';
import { useGlobal } from './hooks';
import query from './PersistState.query';

const PersistState = () => {
  // Чтобы не получать обратку от storage subscribe
  // вводим флаг, который на одну попытку пропускает
  // сохранение текущего состояни в storage,
  // потому что по сути там тоже самое состояние.
  // Аналогично... нет никакого смысла перезаписывать состояние
  // при componentDidMount (первый вызов useEffect)
  const allowSave = useRef(false);
  const [globalState, globalActions] = useGlobal();

  const [res, executeQuery] = useQuery({
    query,
    variables: {
      token: globalState.token,
      addedIds: globalState.cart.addedIds.map(Number),
    },
    pause: globalState.cart.addedIds.length === 0 && !globalState.token,
  });

  useEffect(() => {
    const refetch = () => executeQuery({ requestPolicy: 'network-only' });
    const t = setInterval(refetch, 30000);

    return () => clearInterval(t);
  }, [executeQuery]);

  useEffect(() => {
    if (res.data) {
      globalActions.updateServerData(res.data);
    }
  }, [globalActions, res.data]);

  useEffect(() => {
    if (allowSave.current) {
      storage.setItem('cart', globalState.cart.quantityById);
    } else {
      allowSave.current = true;
    }
  }, [globalState.cart.quantityById]);

  useEffect(() => {
    if (allowSave.current) {
      storage.setItem('token', globalState.token);
    } else {
      allowSave.current = true;
    }
  }, [globalState.token]);

  useEffect(
    () =>
      storage.subscribe(['token', 'cart'], (key, value) => {
        allowSave.current = false;
        globalActions.restore({ [key]: value });
      }),
    [globalActions, globalActions.restore]
  );

  return null;
};

export default PersistState;
