import React from 'react';
import cn from 'classnames';
import { useGlobal } from './hooks';
import ModalWindow from './ModalWindow';
import Auth from './Auth';
import Loading from './Loading';

export const compose = (...functions) => args => functions.reduceRight((arg, fn) => fn(arg), args);

export const connect = selector => Component => {
  const MemoComponent = React.memo(Component);

  return props => {
    const [globalState, globalActions] = useGlobal(null);
    const state = selector(globalState, props);

    return <MemoComponent {...state} globalActions={globalActions} {...props} />;
  };
};

// eslint-disable-next-line react/prop-types
export const withModal = (options = {}) => Component => ({ className, onClose, ...props }) => {
  const windowProps = {
    className: cn(className, options.className),
    style: { maxWidth: options.maxWidth },
    onClose,
  };

  return (
    <ModalWindow {...windowProps}>
      <Component onClose={onClose} {...props} />
    </ModalWindow>
  );
};

const withAuthSelector = state => ({
  viewer: state.viewer,
  token: state.token,
});

export const withAuth = options =>
  compose(connect(withAuthSelector), Component => props => {
    if (props.viewer) {
      return <Component {...options} {...props} />;
    }

    if (props.token) {
      return <Loading />;
    }

    return <Auth {...options} {...props} />;
  });
