import login from './Login';
import password from './Password';
import cart from './Cart';
import my from './My';
import checkout from './Checkout';
import finish from './Finish';

export default {
  login,
  password,
  checkout,
  finish,
  cart,
  my,
};
