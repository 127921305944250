import React from 'react';
import PropTypes from 'prop-types';
import DropdownComp from 'react-dropdown';
import 'react-dropdown/style.css';
import { getRowsDropDown } from 'utils/helper';
import s from './Dropdown.module.css';

const getRowData = (val, i, data) => {
  let newVal = val;
  let { freshType } = data;
  if (newVal > 1000 && freshType === 'гр') {
    newVal /= 1000;
    freshType = 'кг';
  }
  return {
    value: i + 1,
    label: `${newVal} ${freshType}`,
  };
};

const Dropdown = ({
  data,
  onChange,
  addToCart,
  value,
  fakeDropDownClassName,
  forceDropdown,
  cartProduct,
  ...props
}) => {
  const onSelect = o => onChange(o.value);
  const onSelectWithAdd = o => {
    onChange(o.value);
    addToCart(data, o.value);
  };

  const freshStep = data.freshStep || 1;

  const rowsDropDown = getRowsDropDown(data);
  const options = [];

  const max = data.fresh_count_item || 20;

  /* eslint-disable no-plusplus */
  for (let i = 0; i < Math.floor(max / freshStep || 1); i++) {
    const index = (i + 1) * freshStep - 1;
    options[i] = getRowData(rowsDropDown[index], i, data);
  }

  return (
    <React.Fragment>
      {!forceDropdown && (
        <Select
          value={value}
          onChange={cartProduct || data.by_request ? onSelect : onSelectWithAdd}
          data={data}
          options={options}
          className={fakeDropDownClassName}
        />
      )}
      <DropdownComp
        options={options}
        onChange={onSelect}
        value={options.find(o => o.value === value)}
        className={!forceDropdown ? s.computerDropdown : undefined}
        {...props}
      />
    </React.Fragment>
  );
};

const getLabel = (options, value) => {
  const item = options.find(i => i.value === value);
  return item ? item.label : '???';
};

const Select = ({ data, value, options, onChange, className }) => (
  <React.Fragment>
    <div className={`${s.fakeDropdown} ${className} dropdown`}>
      {getLabel(options, value)}
      <div className={s.arrowWrapper}>
        <div className={s.arrow} />
      </div>
    </div>
    <select className={s.select} onChange={e => onChange({ value: parseFloat(e.target.value) })} value={value}>
      <option disabled label={data.name}>
        {data.name}
      </option>
      {options.map(o => (
        <option value={o.value} label={`${o.label} за ${o.value * data.finalPrice} р`} key={o.value}>
          {`${o.label} за ${o.value * data.finalPrice} р`}
        </option>
      ))}
    </select>
  </React.Fragment>
);

Select.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Select.defaultProps = {
  className: undefined,
};

Dropdown.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  fakeDropDownClassName: PropTypes.string,
  forceDropdown: PropTypes.bool,
  cartProduct: PropTypes.bool,
};

Dropdown.defaultProps = {
  fakeDropDownClassName: undefined,
  forceDropdown: false,
  cartProduct: false,
};

export default Dropdown;
