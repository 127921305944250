import React, { useState, useEffect } from 'react';

const Context = React.createContext();

const SearchContext = props => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useEffect(() => {
    if (!query) {
      setDebouncedQuery('');
      return undefined;
    }

    const t = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500);

    return () => clearTimeout(t);
  }, [query]);

  return <Context.Provider value={{ query, debouncedQuery, setQuery }} {...props} />;
};

SearchContext.Consumer = Context.Consumer;
// TODO: refact
SearchContext.Value = Context;

export default SearchContext;
