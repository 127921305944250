import React from 'react';
import PropTypes from 'prop-types';
import './Checkbox.module.css';

const Checkbox = ({ id, children, className, ...props }) => (
  <label styleName="root" htmlFor={id} className={className}>
    <input styleName="input" type="checkbox" id={id} {...props} />
    <span styleName="checkbox" />
    <span>{children}</span>
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

Checkbox.defaultProps = {
  children: undefined,
  className: undefined,
};

export default Checkbox;
