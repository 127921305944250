import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useMutation } from 'urql';
import cn from 'classnames';
import { validatePassword } from 'utils/validators';
import Input from './Input';
import Button from './Button';
import Loading from './Loading';
import './MyChangePasswordTab.module.css';

const mutation = `
  mutation($password: String, $new_password: String) {
    customer {
      changePassword(password: $password, new_password: $new_password) {
        SID
        isLoggedIn
      }
    }
  }
`;

const validateRenewPassword = (v, allValues) => {
  if (v !== allValues.new_password) {
    return 'Пароли должны совпадать';
  }

  return undefined;
};

const MyChangePasswordTab = ({ mob }) => {
  const [res, executeMutation] = useMutation(mutation);

  return (
    <Form
      onSubmit={executeMutation}
      render={({ handleSubmit, submitting }) => (
        <form styleName={cn('form-last', { mob })} onSubmit={handleSubmit}>
          {res.fetching && <Loading />}
          <h3 styleName="form-title">Смена пароля</h3>
          <Input
            placeholder="*****"
            label="Введите старый пароль"
            type="password"
            name="password"
            validate={validatePassword}
          />
          <div styleName="form-password_new">
            <Input
              placeholder="*****"
              label="Введите новый пароль"
              type="password"
              name="new_password"
              validate={validatePassword}
            />
          </div>
          <Input
            placeholder="*****"
            label="Повторите новый пароль"
            type="password"
            name="renew_password"
            validate={validateRenewPassword}
          />
          <div styleName="footer">
            <Button disabled={submitting} type="submit" styleName="submit">
              Сменить
            </Button>
            <div styleName="success-message">
              {res.data && res.data.customer.changePassword.SID && 'Профиль успешно обновлен'}
            </div>
          </div>
        </form>
      )}
    />
  );
};

MyChangePasswordTab.propTypes = {
  mob: PropTypes.bool.isRequired,
};

export default MyChangePasswordTab;
