import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'components/Dropdown';
import { connect } from '../hocs';
import './Footer.module.css';

const Footer = ({ data, quantity, globalActions, fakeDropDownClassName }) => {
  const changeQuantity = q => globalActions.cart.add(data, q);

  return (
    <div styleName="root">
      <Dropdown
        value={quantity}
        data={data}
        onChange={changeQuantity}
        fakeDropDownClassName={fakeDropDownClassName}
        cartProduct
      />
      <div styleName="buy" role="button">
        <div styleName="price">{data.finalPrice * quantity}р</div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  globalActions: PropTypes.object.isRequired,
  fakeDropDownClassName: PropTypes.string,
};

Footer.defaultProps = {
  fakeDropDownClassName: undefined,
};

const selector = (state, props) => ({
  quantity: state.cart.quantityById[props.data.entity_id] || props.data.fresh_step || 1,
});

const enhance = connect(selector);

export default enhance(Footer);
