import React from 'react';
import PropTypes from 'prop-types';
import './AnimatedLogo.module.css';

const AnimatedLogo = ({ className, eyesClassName }) => (
  <svg className={className} styleName="benefit-icon" viewBox="0 0 414 775.034">
    <path
      d="M233.387 27.996c-23.098 23.098-31.895 55.063-26.43 84.93 29.87 5.47 61.836-3.328 84.934-26.422 23.098-23.098 31.89-55.067 26.426-84.934-29.87-5.465-61.836 3.332-84.93 26.426"
      fill="#f15e22"
    />
    <path
      d="M207.61 146.03h-.188a7.193 7.193 0 0 1-7.008-7.374c.024-.926 1.453-75.96-30.887-103.508a7.192 7.192 0 0 1-.808-10.14 7.196 7.196 0 0 1 10.14-.813c37.6 32.035 36.023 111.46 35.937 114.828a7.193 7.193 0 0 1-7.188 7.008"
      fill="#373536"
    />
    <path
      d="M307.387 261.805l-1.426-6.875c-2.526-12.196-4.347-24.516-5.913-36.868-5.883-46.26-45.387-82.027-93.246-82.027-47.972 0-87.55 35.934-93.284 82.352-1.508 12.2-3.336 24.36-5.817 36.398l-1.485 7.203c-14.692 71.297-41.766 139.29-78.332 202.23C10.153 494.74 0 530.2 0 568.032c0 114.673 92.438 207.064 207.11 207.005 114.273-.058 206.89-92.715 206.89-207 0-38.41-10.46-74.367-28.69-105.19-36.833-62.29-63.236-130.18-77.923-201.04"
      fill="#f15e22"
    />
    <path
      d="M134.695 626.375c0 22.723-18.418 41.14-41.144 41.14-22.722 0-41.144-18.417-41.144-41.14S70.828 585.23 93.55 585.23c22.727 0 41.145 18.422 41.145 41.145"
      fill="#fff"
    />
    <path
      d="M361.594 626.375c0 22.723-18.422 41.14-41.145 41.14-22.727 0-41.145-18.417-41.145-41.14s18.418-41.145 41.144-41.145c22.722 0 41.144 18.422 41.144 41.145"
      fill="#fff"
    />
    <path
      d="M301.758 553.102H81.05a7.195 7.195 0 0 1-1.1-14.305 89.46 89.46 0 0 1 27.202 0 7.2 7.2 0 0 1 6.075 7.66 7.195 7.195 0 0 1-7.172 6.645M332.95 553.102h-25.005a7.195 7.195 0 0 1-1.097-14.305 89.435 89.435 0 0 1 27.2 0 7.195 7.195 0 0 1-1.098 14.305M207 729.242c-16.574 0-30.063-13.488-30.063-30.062a7.194 7.194 0 0 1 14.387 0c0 8.64 7.03 15.672 15.676 15.672 8.645 0 15.676-7.032 15.676-15.672a7.194 7.194 0 0 1 14.387 0c0 16.574-13.49 30.062-30.063 30.062"
      fill="#373536"
    />
    <path
      className={eyesClassName}
      styleName="eyes-animation"
      d="M74.863 589.723c-13.324 6.808-22.453 20.66-22.453 36.652 0 15.988 9.13 29.84 22.453 36.648 13.328-6.808 22.457-20.66 22.457-36.648 0-15.992-9.13-29.844-22.457-36.652"
      fill="#373536"
    />
    <path
      className={eyesClassName}
      styleName="eyes-animation"
      d="M301.758 589.723c-13.324 6.808-22.453 20.66-22.453 36.652 0 15.988 9.13 29.84 22.453 36.648 13.328-6.808 22.457-20.66 22.457-36.648 0-15.992-9.13-29.844-22.457-36.652"
      fill="#373536"
    />
  </svg>
);

AnimatedLogo.propTypes = {
  className: PropTypes.string,
  eyesClassName: PropTypes.string,
};

AnimatedLogo.defaultProps = {
  className: undefined,
  eyesClassName: undefined,
};

export default AnimatedLogo;
