import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Input from './Input';
import mask from './PhoneInput.mask';

export { mask };

const PhoneInput = ({ className, ...props }) => (
  <Input component={MaskedInput} mask={mask} className={className} {...props} />
);

PhoneInput.propTypes = {
  className: PropTypes.string,
};

PhoneInput.defaultProps = {
  className: undefined,
};

export default PhoneInput;
