import React from 'react';
import AnimatedLogo from 'components/AnimatedLogo';
import s from './Loading.module.css';

const Loading = () => (
  <div styleName="overlay">
    <AnimatedLogo styleName="logo" eyesClassName={s.eyes} />
    <span styleName="title">Пожалуйста, подождите, идёт обработка данных</span>
  </div>
);

export default Loading;
