import React, { useState } from 'react';
import AuthEnterEmail from './AuthEnterEmail';
import AuthEnterPassword from './AuthEnterPassword';

const tabs = {
  login: AuthEnterEmail,
  password: AuthEnterPassword,
};

const Auth = () => {
  const [tab, setTab] = useState({ name: 'email', params: {} });
  const Component = tabs[tab.name] || tabs.login;
  const openModal = (name, params = {}) => setTab({ name, params });

  return <Component openModal={openModal} {...tab.params} />;
};

export default Auth;
