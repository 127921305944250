module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-71223737-1"],"gtagConfig":{"custom_map":{"dimension1":"ecomm_prodid","dimension2":"ecomm_pagetype","dimension3":"ecomm_totalvalue"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1502040813645737"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
