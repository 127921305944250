import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'unchanged';
import { connect } from './hocs';

const criteo = (...args) => {
  // eslint-disable-next-line no-console
  console.log('criteo', ...args);

  if (process.env.NODE_ENV === 'development') {
    return null;
  }

  if (window.criteo_q) {
    window.criteo_q.push(
      { event: 'setAccount', account: 51743 },
      { event: 'setSiteType', type: window.innerWidth <= 666 ? 'm' : 'd' },
      ...args
    );
  }
  return null;
};

const fbq = (...args) => {
  // eslint-disable-next-line no-console
  console.log('facebook', ...args);

  if (window.fbq) {
    window.fbq('track', ...args);
  }
};

const gtag = (...args) => {
  // eslint-disable-next-line no-console
  console.log('gtag', ...args);

  if (window.gtag) {
    window.gtag(...args);
  }
};

const yaCounterGoal = (...args) => {
  // eslint-disable-next-line no-console
  console.log('yaCounter', ...args);

  if (window.ym) {
    window.ym(33509038, 'reachGoal', ...args);
  }
};

const tmr = (...args) => {
  // eslint-disable-next-line no-console
  console.log('tmr', ...args);

  // eslint-disable-next-line no-underscore-dangle
  if (window._tmr) {
    // eslint-disable-next-line no-underscore-dangle
    window._tmr.push(...args);
  }
};

const dataLayerPush = (action, data) => {
  // eslint-disable-next-line no-console
  console.log('dataLayerPush', action, data);

  if (window.dataLayer) {
    window.dataLayer.push({
      ecommerce: {
        currencyCode: 'RUB',
        [action]: data,
      },
    });
  }
};

const types = {
  viewHome: ({ email }) => {
    criteo({ event: 'setEmail', email }, { event: 'viewHome' });
  },
  purchase: ({ email, orderId, items }) => {
    // eslint-disable-next-line no-console
    console.log('purchase', email, orderId, items);
    const itemSum = items.reduce((sum, i) => sum + i.price * i.quantity, 0);

    criteo({ event: 'setEmail', email }, { event: 'trackTransaction', id: orderId, item: items });

    gtag('event', 'createOrder', {
      value: itemSum,
      ecomm_prodid: items.map(i => i.id),
      ecomm_pagetype: 'purchase',
      ecomm_totalvalue: itemSum,
    });

    // https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking
    fbq('Purchase', {
      currency: 'RUB',
      content_type: 'product',
      value: itemSum,
      contents: items.map(item => ({
        id: item.id,
        quantity: item.quantity,
      })),
    });

    yaCounterGoal('PURCHASE');

    dataLayerPush('purchase', {
      actionField: {
        id: orderId,
      },
      products: items,
    });

    items.forEach(item => {
      tmr({
        type: 'itemView',
        productid: item.id,
        pagetype: 'cart',
        list: '1',
        totalvalue: item.price,
      });
    });
  },
  viewBasket: ({ email, items, itemSum }) => {
    criteo({ event: 'setEmail', email }, { event: 'viewBasket', item: items });

    gtag('event', 'rem_open_cart', {
      ecomm_prodid: items.map(i => i.id),
      ecomm_pagetype: 'cart',
      ecomm_totalvalue: itemSum,
    });

    fbq('ViewContent', {
      content_type: 'product',
      content_ids: items.map(i => i.id),
      value: itemSum,
      currency: 'RUB',
    });

    yaCounterGoal('4');

    items.forEach(item => {
      tmr({
        type: 'itemView',
        productid: item.id,
        pagetype: 'cart',
        list: '1',
        totalvalue: '500',
      });
    });
  },
  viewItem: ({ email, id, price }) => {
    criteo({ event: 'setEmail', email }, { event: 'viewItem', item: id });

    gtag('event', 'rem_view_product', {
      ecomm_prodid: id,
      ecomm_pagetype: 'product',
      ecomm_totalvalue: price,
    });

    fbq('ViewContent', {
      content_type: 'product',
      content_ids: [id],
    });

    tmr({
      type: 'itemView',
      productid: id,
      pagetype: 'home',
      list: '1',
      totalvalue: '500',
    });
  },
  viewList: ({ email, ids }) => {
    criteo({ event: 'setEmail', email }, { event: 'viewList', item: ids });

    fbq('ViewContent', {
      content_type: 'product',
      content_ids: ids,
    });

    ids.forEach(id => {
      tmr({
        type: 'itemView',
        productid: id,
        pagetype: 'home',
        list: '1',
        totalvalue: '500',
      });
    });

    gtag('event', 'ret_view_category_row', {
      ecomm_pagetype: 'home',
      ecomm_prodid: ids,
    });
  },
  addToCart: (id, price) => {
    gtag('event', 'addToCart', {
      value: price,
      ecomm_pagetype: 'home',
      ecomm_prodid: id,
      ecomm_totalvalue: price,
    });

    fbq('AddToCart', {
      currency: 'RUB',
      content_type: 'product',
      content_ids: [id],
      value: price,
    });

    yaCounterGoal('ADD_TO_CART');

    dataLayerPush('add', { id, price });

    tmr({
      type: 'itemView',
      productid: id,
      pagetype: 'home',
      list: '1',
      totalvalue: price,
    });
  },
  initiateCheckout: () => {
    fbq('InitiateCheckout');
    gtag('event', 'initiateCheckout');
    yaCounterGoal('5');
  },
  openModal: name => {
    gtag('event', `openModal:${name}`);
  },
};

const Analytics = ({ viewer, type, ...props }) => {
  const email = get('info.email', viewer) || '';

  useEffect(() => types[type]({ email, ...props }), [email, props]);

  return null;
};

Analytics.propTypes = {
  viewer: PropTypes.object,
  type: PropTypes.oneOf(Object.keys(types)).isRequired,
};

Analytics.defaultProps = {
  viewer: null,
};

const selector = state => ({
  viewer: state.viewer,
});

const enhance = connect(selector);

export const analytics = types;

export default enhance(Analytics);

export const useRemarketingTracking = (eventName, pageType, productId, totalValue) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line
      console.log('useRemarketingTracking', eventName, pageType, productId, totalValue);
    }

    if (window.gtag && (!Array.isArray(productId) || productId.length > 0)) {
      window.gtag('event', eventName, {
        ecomm_prodid: productId,
        ecomm_pagetype: pageType,
        ecomm_totalvalue: totalValue,
      });
    }
  }, [eventName, pageType, productId, totalValue]);
};
