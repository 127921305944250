// eslint-disable-next-line import/prefer-default-export
export const viewPage = path => {
  if (window.gtag) {
    // TODO: get track id from env
    window.gtag('config', 'UA-71223737-1', { page_path: path });
  }

  if (window.yaCounter) {
    window.yaCounter.hit(path);
  }

  if (window.fbq) {
    window.fbq('track', 'virtualPageview', {
      url: 'path',
    });
  }
};
