import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './Tabs.module.css';

const Tabs = ({ items, currentTab, className, mob, setTab }) => (
  <div styleName="root" className={className}>
    <div styleName="tabs">
      {Object.keys(items).map(item => (
        <button
          styleName={cn('tab', { active: currentTab === item, mob })}
          type="button"
          key={item}
          onClick={() => setTab(item)}
        >
          {items[item].title}
        </button>
      ))}
    </div>
    <div styleName="content">{items[currentTab].content}</div>
  </div>
);

Tabs.propTypes = {
  items: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  className: PropTypes.string,
  mob: PropTypes.bool,
};

Tabs.defaultProps = {
  className: undefined,
  mob: false,
};

export default Tabs;
