import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { viewPage } from 'utils/analytics';
import { analytics } from 'components/Analytics';
import { debounce } from 'throttle-debounce';
import { setWith } from 'unchanged';
import qs from 'query-string';
import * as storage from 'utils/storage';
import globalHook from 'utils/useGlobalHook';
import { getRestoredState, actions } from '../store';

let observer = null;

if (typeof window !== 'undefined' && window.IntersectionObserver) {
  const callback = entries => {
    entries.forEach(e => {
      if (e.intersectionRatio > 0.2) {
        viewPage(e.target.dataset.path);

        if (e.target.dataset.ids) {
          analytics.viewList({
            ids: e.target.dataset.ids.split(','),
          });
        }
      }
    });
  };

  const options = {
    threshold: 0.2,
  };

  observer = new IntersectionObserver(debounce(1000, callback), options);
}

// eslint-disable-next-line import/prefer-default-export
export const useVirtualPageObserver = () => {
  const ref = useRef();

  useLayoutEffect(() => {
    setTimeout(() => {
      const el = ref.current;

      if (el && observer) {
        observer.observe(el);
        return () => observer.unobserve(el);
      }

      return undefined;
    }, 1000);
  }, [ref]);

  return ref;
};

export const useClickOutside = (callback, ...args) => {
  const ref = useRef();

  useEffect(() => {
    const el = ref.current;

    if (el) {
      const fn = e => {
        if (el && !el.contains(e.target)) {
          callback(...args);
        }
      };

      document.addEventListener('click', fn);

      return () => document.removeEventListener('click', fn);
    }

    return undefined;
  }, [ref]);

  return ref;
};

// state managment

let queryState = {};

if (typeof window !== 'undefined') {
  try {
    const q = qs.parse(window.location.search);

    if (q.restoreState) {
      const { cart, token } = JSON.parse(q.restoreState);
      queryState = { cart, token };
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }
}

const initialState = getRestoredState({
  token: storage.getItem('token'),
  cart: storage.getItem('cart'),
  ...queryState,
});

const wrapAddToCartAnalytics = action => (data, ...args) => {
  action(data, ...args);
  analytics.addToCart(data.entity_id, data.finalPrice || data.price);
};

const storeInitializer = store => {
  // eslint-disable-next-line no-param-reassign
  store.actions = setWith(wrapAddToCartAnalytics, 'cart.add', store.actions);
};

export const useGlobal = globalHook(React, initialState, actions, storeInitializer);

export const useDefferedArray = (items, delay = 3000, initialLength = 6) => {
  const [limit, setLimit] = useState(initialLength);

  useEffect(() => {
    setTimeout(() => {
      setLimit(null);
    }, delay);
  }, [setLimit]);

  return limit !== null ? items.slice(0, limit) : items;
};

export const useAlgoliaSearch = query => {
  const [hits, setHits] = useState(null);

  useEffect(() => {
    if (query) {
      fetch('https://HNVX1K4P94-dsn.algolia.net/1/indexes/moscowfresh/query', {
        method: 'POST',
        headers: {
          'X-Algolia-API-Key': '7d1efc933605528e4548bf8eff835168',
          'X-Algolia-Application-Id': 'HNVX1K4P94',
        },
        body: JSON.stringify({
          params: qs.stringify({
            query,
            hitsPerPage: 40,
          }),
        }),
      })
        .then(res => res.json())
        .then(data => {
          setHits(data.hits);
        });
    }
  }, [query]);

  return query ? hits : null;
};

export const useFetchDeliveryIntervals = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          {
            deliveryIntervals {
              date
              from
              to
            }
          }
        `,
      }),
    };

    fetch('/v5/graphql', options)
      .then(r => r.json())
      .then(res => setData(res.data.deliveryIntervals));
  }, []);

  return data;
};
