import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Radio.module.css';

const Radio = ({ children, checked, disabled, ...props }) => (
  <label styleName="root">
    {children}
    <div styleName={cx('dot', { active: checked, disabled })} />
    <input styleName="input" type="radio" checked={checked} disabled={disabled} {...props} />
  </label>
);

Radio.propTypes = {
  children: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  children: undefined,
  disabled: false,
  checked: false,
};

export default Radio;
