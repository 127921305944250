import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import s from './FadeImage.module.css';

const cx = classNames.bind(s);

class FadeImage extends React.Component {
  constructor(props) {
    super(props);

    if (typeof window === 'undefined') {
      this.state = {
        loaded: true,
        showed: props.initDelay === 0,
      };

      return;
    }

    const img = new Image();
    img.src = props.src;

    this.state = {
      loaded: img.complete,
      showed: props.initDelay === 0 || img.complete,
    };

    if (!this.state.loaded) {
      this.load = () => {
        this.setState({ loaded: true });
      };
    }
  }

  componentDidMount() {
    const { initDelay } = this.props;

    if (initDelay > 0) {
      this.timeout = setTimeout(() => this.setState({ showed: true }), initDelay);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    const { className, lqip, initDelay, ...props } = this.props;
    const { showed } = this.state;

    if (!showed) {
      if (lqip) {
        return <div styleName="lqip" style={{ backgroundImage: `url(${lqip})` }} />;
      }

      return null;
    }

    return <img {...props} alt={props.alt} className={cx(className, 'root', this.state)} onLoad={this.load} />;
  }
}

FadeImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  initDelay: PropTypes.number,
  lqip: PropTypes.string,
};

FadeImage.defaultProps = {
  className: undefined,
  lqip: undefined,
  initDelay: 0,
};

export default FadeImage;
