import React from 'react';
import { withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import ClientProvider from './components/ClientProvider';
import TrackCart from './components/TrackCart';
import ErrorBoundary from './components/ErrorBoundary';
import SearchContext from './components/SearchContext';
import ModalRenderer from './components/ModalRenderer';
import PersistState from './components/PersistState';
import modals from './modals';
import './global.css';

import './fonts/index.css';

const ReactHint = ReactHintFactory(React);

const meta = [
  {
    name: 'description',
    content:
      'Экспресс-доставка свежих продуктов с рынка. По каждому заказу мы сами выбираем самые свежие продукты, аккуратно упаковываем их, привозим в удобное для вас время и гарантируем качество.',
  },
  {
    name: 'keywords',
    content:
      'MoscowFresh, Moscow Fresh, экспресс-доставка свежих продуктов, свежие продукты, заказ продуктов, заказ продуктов на дом, продукты с рынка, фрукты и овощи, молочные продукты, свежая рыба, домашнее мясо, орехи, сладости и сухофрукты, мёд, хлеб, соленья и прочее, огурчики бакинские, сливочное масло, форель карельская охлажденная, курица для жарки, свиной фарш, белая халва, курага, папайя цукаты, свиной фарш',
  },
];

// eslint-disable-next-line react/prop-types
export default ({ element, props }) => (
  <ErrorBoundary>
    <ClientProvider>
      <PersistState />
      <ReactHint autoPosition events attribute="data-rt" />
      <SearchContext>
        <TrackCart />
        <Helmet titleTemplate="%s | Moscowfresh" defaultTitle="MoscowFresh" meta={meta}>
          <html lang="ru" />
          <link rel="icon" href={withPrefix('favicon.ico')} type="image/x-icon" />
          <link rel="shortcut icon" href={withPrefix('favicon.ico')} type="image/x-icon" />
          <script type="text/javascript" src="//static.criteo.net/js/ld/ld.js" async />
          <script>window.criteo_q = window.criteo_q || [];</script>
        </Helmet>
        <ModalRenderer modals={modals} {...props}>
          {element}
        </ModalRenderer>
      </SearchContext>
    </ClientProvider>
  </ErrorBoundary>
);
