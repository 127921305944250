/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Radio from 'components/Radio';
import { useClickOutside } from 'components/hooks';
import cn from 'classnames';
import './DeliveryTimeDropdown.module.css';

const DeliveryTimeDropdown = ({ className, options, value, onChange }) => {
  const [opened, setOpened] = useState(false);
  const rootEl = useClickOutside(setOpened, false);
  const toggle = () => setOpened(!opened);

  const middle = Math.ceil(options.length / 2);
  const change = item => () => {
    setOpened(false);
    if (onChange) onChange(item.value);
  };

  const renderItem = o => (
    <Radio checked={o.value === value} key={o.value} disabled={o.disabled} onChange={change(o)}>
      {o.label}
    </Radio>
  );

  const item = options.find(o => o.value === value);

  return (
    <div styleName="root" ref={rootEl} className={className}>
      <div role="button" tabIndex={0} onClick={toggle} styleName={cn('display', { opened })}>
        {item && item.label}
      </div>
      {opened && (
        <div styleName="pane">
          <div styleName="pane-column">{options.slice(0, middle).map(renderItem)}</div>
          <div styleName="pane-column">{options.slice(middle).map(renderItem)}</div>
        </div>
      )}
    </div>
  );
};

DeliveryTimeDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

DeliveryTimeDropdown.defaultProps = {
  value: undefined,
  onChange: undefined,
  className: undefined,
};

export default DeliveryTimeDropdown;
