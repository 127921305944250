import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { StaticQuery, graphql } from 'gatsby';
import { connect, withModal, compose } from 'components/hocs';
import { useFetchDeliveryIntervals } from 'components/hooks';
import { get } from 'unchanged';
import { useMutation } from 'urql';
import { selectors } from 'store';
import ModalLink from 'components/ModalLink';
import IconArrow from 'components/icons/Arrow';
import { rubles } from 'utils/format';
import Analytics, { analytics } from 'components/Analytics';
import Loading from 'components/Loading';
import CheckoutForm from 'components/CheckoutForm';

import './Checkout.module.css';

const mutation = `
  mutation($address: String!, $comment: String!, $advertising_origin: String!, $payment_method: String!, $bonus_payment: Boolean!, $asap: Boolean!, $phone: String!, $ecash_payment: Boolean!, $date: String!, $name: String!, $items: [CreateOrderItem!]!, $without_overweight: Boolean, $eco_pack: Boolean) {
    cart {
      createOrder2(address: $address, comment: $comment, advertising_origin: $advertising_origin, payment_method: $payment_method, bonus_payment: $bonus_payment, ecash_payment: $ecash_payment, asap: $asap, phone: $phone, date: $date, name: $name, items: $items, without_overweight: $without_overweight, eco_pack: $eco_pack) {
        incrementId
        id
        totals {
          subtotal
          grandtotal
        }
      }
    }
  }
`;

const extractOrder = get('data.cart.createOrder2');
const normalizePhone = v => v.replace(/[^0-9]/g, '').replace(/^7/, '');

const CheckoutModal = ({
  mob,
  onClose,
  itemSum,
  deliveryCost,
  cart,
  asapVisible,
  openModal,
  viewer,
  globalActions,
  deliveryIntervals,
}) => {
  const [res, executeMutation] = useMutation(mutation);
  const freshDeliveryIntervals = useFetchDeliveryIntervals();

  const onSubmit = values => {
    const items = cart.addedIds.map(id => ({
      entity_id: id,
      quantity: cart.quantityById[id],
    }));

    /* eslint-disable no-param-reassign */
    const data = {
      ...values,
      ecash_payment: values.payment_method === 'online',
      date: values.dateTime,
      advertising_origin: 'v3',
      bonus_payment: false,
      items,
      phone: `7${normalizePhone(values.phone)}`,
      comment: values.comment || '',
    };

    executeMutation(data)
      .then(extractOrder)
      .then(result => {
        if (result) {
          globalActions.cart.clear();

          openModal('finish', { incrementId: result.incrementId, id: result.id, paymentMethod: values.payment_method });

          analytics.purchase({
            email: viewer && viewer.info.email,
            orderId: result.id,
            items: cart.products.map(product => ({
              id: product.entity_id,
              quantity: cart.quantityById[product.entity_id],
              price: product.final_price || product.price,
            })),
          });

          return;
        }

        Sentry.withScope(scope => {
          scope.setExtras({ result, data });
          Sentry.captureException(new Error('create order failed'));
        });
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  };

  const viewerInfo = viewer ? viewer.info : {};

  const initialValues = {
    payment_method: 'by_cash',
    accept: true,
    dateTime: null,
    asap: false,
    comment: viewerInfo.comment,
    address: viewerInfo.address,
    name: viewerInfo.name,
    phone: viewerInfo.phone && viewerInfo.phone.replace(/[^0-9]/g, '').replace(/^7/, ''),
  };

  let deliveryText = `доставка в пределах МКАД всего за ${rubles(deliveryCost)}`;

  if (deliveryCost === 0) {
    deliveryText = 'бесплатная доставка';
  }

  const titleMessage = (
    <div styleName="title-message">
      Общая стоимость {rubles(itemSum)} + {deliveryText}
    </div>
  );

  return (
    <div styleName="main" id="modal-checkout">
      {res.fetching && <Loading />}
      <Analytics type="initiateCheckout" />
      {mob ? (
        <div styleName="header">
          <ModalLink component="button" to="cart" onClick={onClose} styleName="mob-close">
            <IconArrow color="mini-orange" styleName="header-icon" />
          </ModalLink>
          {titleMessage}
        </div>
      ) : (
        <div styleName="header">
          <ModalLink component="button" to="cart" single styleName="back">
            <IconArrow color="mini-orange" size={12} styleName="back-arrow" />
            Корзина
          </ModalLink>
          <div styleName="title">Доставка</div>
          {titleMessage}
        </div>
      )}
      <CheckoutForm
        onClose={onClose}
        onSubmit={onSubmit}
        initialValues={initialValues}
        asapVisible={asapVisible}
        deliveryIntervals={freshDeliveryIntervals || deliveryIntervals}
      />
    </div>
  );
};

CheckoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  itemSum: PropTypes.number.isRequired,
  deliveryCost: PropTypes.number.isRequired,
  cart: PropTypes.object.isRequired,
  asapVisible: PropTypes.bool.isRequired,
  mob: PropTypes.bool.isRequired,
  globalActions: PropTypes.object.isRequired,
  viewer: PropTypes.object,
  deliveryIntervals: PropTypes.array.isRequired,
};

CheckoutModal.defaultProps = {
  viewer: undefined,
};

const selector = state => ({
  cart: state.cart,
  itemSum: selectors.getItemSum(state),
  deliveryCost: selectors.getDeliveryCost(state),
  asapVisible: selectors.getAsapVisible(state),
  viewer: state.viewer,
});

const enhance = compose(
  withModal({
    maxWidth: 700,
  }),
  connect(selector)
);

const EnhancedCheckout = enhance(CheckoutModal);

export default props => (
  <StaticQuery
    query={graphql`
      query CheckoutModalQuery {
        v5 {
          deliveryIntervals {
            date
            from
            to
          }
        }
      }
    `}
    render={data => <EnhancedCheckout {...props} deliveryIntervals={data.v5.deliveryIntervals} />}
  />
);
