/* eslint-disable */
/* https://github.com/zeit/fetch-retry/blob/master/index.js */

import retry from 'async-retry';

const MIN_TIMEOUT = 10;
const MAX_RETRIES = 3;
const FACTOR = 5;

export default function setup(fetch) {
  function fetchRetry(url, opts = {}) {
    const retryOpts = Object.assign(
      {
        // timeouts will be [ 10, 50, 250 ]
        minTimeout: MIN_TIMEOUT,
        retries: MAX_RETRIES,
        factor: FACTOR,
      },
      opts.retry
    );

    if (opts.onRetry) {
      retryOpts.onRetry = error => {
        opts.onRetry(error, opts);
        if (opts.retry && opts.retry.onRetry) {
          opts.retry.onRetry(error);
        }
      };
    }

    return retry((bail, attempt) => {
      const { method = 'GET' } = opts;
      const isRetry = attempt < retryOpts.retries;

      return fetch(url, opts)
        .then(res => {
          if (res.status >= 404 && res.status < 600 && isRetry) {
            const err = new Error(res.statusText);
            err.code = err.status = err.statusCode = res.status;
            err.url = url;
            throw err;
          } else {
            return res;
          }
        })
        .catch(err => {
          if (err.message.indexOf('abort') > -1) {
            bail(err);
            return;
          }

          console.log('fetch-retry', `${method} ${url} error (${err.status}). ${isRetry ? 'retrying' : ''}`, err);
          throw err;
        });
    }, retryOpts);
  }

  for (const key of Object.keys(fetch)) {
    fetchRetry[key] = fetch[key];
  }
  fetchRetry.default = fetchRetry;

  return fetchRetry;
}
