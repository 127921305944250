import React from 'react';
import PropTypes from 'prop-types';
import plural from 'plural-ru';
import { useGlobal } from 'components/hooks';
import { withModal, withAuth, compose } from 'components/hocs';

import Tabs from 'components/Tabs';
import MyDepositTab from 'components/MyDepositTab';
import MyPersonalTab from 'components/MyPersonalTab';
import MyHistoryTab from 'components/MyHistoryTab';
import MyChangePasswordTab from 'components/MyChangePasswordTab';
import cn from 'classnames';

import './My.module.css';

const DEFAULT_TAB = 'history';

const getViewer = s => s.viewer;
const getUpdateToken = actions => actions.updateToken;

const My = ({ onClose, openModal, ct }) => {
  const [viewer, updateToken] = useGlobal(getViewer, getUpdateToken);

  if (!viewer) {
    return null;
  }

  const signOutAndClose = () => {
    updateToken(null);
    onClose();
  };

  const setTab = tab => {
    openModal('my', { ct: tab });
  };

  const tabs = {
    history: {
      title: 'История заказов',
      content: <MyHistoryTab openModal={openModal} viewer={viewer} />,
    },
    deposit: {
      title: 'История депозита',
      content: <MyDepositTab viewer={viewer} />,
    },
    personal: {
      title: 'Персональные данные',
      content: <MyPersonalTab viewer={viewer} />,
    },
    password: {
      title: 'Сменить пароль',
      content: <MyChangePasswordTab viewer={viewer} />,
    },
  };

  let currentTab = ct;

  if (!currentTab || Object.keys(tabs).indexOf(currentTab) === -1) {
    // eslint-disable-next-line no-param-reassign
    currentTab = DEFAULT_TAB;
  }

  const isFreeDelivery = viewer && viewer.freeDeliveryDays > 0;

  return (
    <div styleName="root">
      <button onClick={signOutAndClose} type="button" styleName="logout">
        <div styleName="logout-icon" />
        Выйти
      </button>
      <div styleName="header">
        <div styleName={cn('title', { [`with-pull`]: isFreeDelivery })}>Личный кабинет</div>
        <div styleName="sub-title">
          {`${plural(viewer.totalOrders, 'Сохранен', 'Сохранено', 'Сохранено')} ${plural(
            viewer.totalOrders,
            '%d заказ',
            '%d заказа',
            '%d заказов'
          )}`}
        </div>
        {isFreeDelivery && (
          <div styleName="sub-title">{`У вас дейстует бесплатная доставка до ${viewer.freeDeliveryUntil}`}</div>
        )}
      </div>
      <Tabs styleName="tabs" currentTab={currentTab} items={tabs} setTab={setTab} />
    </div>
  );
};

My.propTypes = {
  onClose: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  viewer: PropTypes.object,
  ct: PropTypes.string,
};

My.defaultProps = {
  ct: undefined,
  viewer: undefined,
};

const enhance = compose(
  withModal({
    maxWidth: 1000,
  }),
  withAuth()
);

export default enhance(My);
