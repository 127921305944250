import isEmail from 'fbjs/lib/isEmail';

const NORMAL_PHONE_LENGTH = 11; // "79999999999".length
const notDigit = /[^\d]+/g;

const rusSymbols = /[а-яА-ЯЁё]/;
const minLengthPassword = 4;

export const validatePassword = value => {
  if (!value) {
    return 'Введите пароль';
  }

  if (value.toString().length < minLengthPassword) {
    return 'Слишком короткий пароль';
  }

  return undefined;
};

export const validateMail = value => {
  if (!value) {
    return 'Введите пожалуйста свой логин/email';
  }
  if (rusSymbols.test(value)) {
    return 'email/login не может содержать русских символов';
  }
  if (!isEmail(value)) {
    return 'Почта введена некорректно';
  }
  return undefined;
};

export const validatePhone = value => {
  if (!value) {
    return 'Введите телефон';
  }

  if (value.toString().replace(notDigit, '').length !== NORMAL_PHONE_LENGTH) {
    return 'Введите номер полностью';
  }

  return undefined;
};

export const validateName = value => {
  if (!value) {
    return 'Введите ваше имя';
  }

  return undefined;
};
