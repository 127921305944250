// eslint-disable-next-line import/prefer-default-export
export const signinSessionFragment = `
  fragment SigninSession on Session {
    SID
    isLoggedIn
    customer {
      info {
        email
        firstName
        lastname
        phone
        comment
        address
      }
      bonuses {
        total
      }
      haveSuccessfulOrder
      orders(all: false) {
        entity_id
        create_at
        delivered_time
        subtotal
        status
        state
        cashless
        total_qty_ordered
        items {
          entity_id
          item_id
          qty_ordered
          product(force: true) {
            entity_id
            name
            small_image
            description
            weight
            fresh_start_item
            price
            finalPrice
            season {
              color
              text
            }
            text_badge
            is_link_more_info
            url_path
            detox
            eco
            heavy
            raw
            shelf_life_date
            shelf_life_required
            manufacture_date
            qty
            warehouse_qty
            fresh_count_item
            fresh_type_unit
            freshType
          }
        }
      }
      totalOrders
    }
  }
`;
