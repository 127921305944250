import React from 'react';
import PropTypes from 'prop-types';
import './Price.module.css';

const NBSP = '\xa0';
const EN_DASH = '–';

export const formatPrice = value =>
  value
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
    .replace(' ', NBSP);

const Price = ({ value, allowZero, allowFree, ...props }) => {
  if (!value && allowFree) {
    return <span styleName="free">БЕСПЛАТНО</span>;
  }

  if (!value && !allowZero) {
    return <span {...props}>{EN_DASH}</span>;
  }

  return (
    <span {...props}>
      {formatPrice(value)}
      {NBSP}₽
    </span>
  );
};

Price.propTypes = {
  value: PropTypes.number,
  allowZero: PropTypes.bool,
  allowFree: PropTypes.bool,
};

Price.defaultProps = {
  value: undefined,
  allowZero: false,
  allowFree: false,
};

export default Price;
