import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'urql';
import { get } from 'unchanged';
import OrderCard from './OrderCard';
import Button from './Button';
import Loading from './Loading';
import './MyHistoryTab.module.css';

const query = `
  query OrdersQuery($all: Boolean!) {
    session { customer {
      orders(all: $all) {
        entity_id
        create_at
        delivered_time
        subtotal
        status
        state
        cashless
        old
        total_qty_ordered
        items {
          entity_id
          item_id
          qty_ordered
          product(force: true) {
            entity_id
            name
            imageUrl: mainImage(width: 285)
            retinaImageUrl: mainImage(width: 570)
            description
            weight
            fresh_start_item
            price
            finalPrice
            season {
              color
              text
            }
            text_badge
            is_link_more_info
            url_path
            detox
            eco
            heavy
            raw
            shelf_life_date
            shelf_life_required
            manufacture_date
            qty
            warehouse_qty
            fresh_count_item
            fresh_type_unit
            freshType
          }
        }
      }
    } }
  }
`;

const MyHistoryTab = ({ openModal }) => {
  const [all, showAll] = useReducer(() => true, false);
  const [res] = useQuery({
    query,
    variables: { all },
  });

  const orders = get('session.customer.orders', res.data) || [];

  if (res.error) {
    return (
      <div styleName="history">
        Произошла ошибка. Мы отслеживаем каждую ошибку и скоро все исправим. Попробуйте позже.
      </div>
    );
  }

  return (
    <div styleName="history">
      {res.fetching && <Loading />}
      {orders.length > 0 ? <OrdersList openModal={openModal} orders={orders} /> : <EmptyState />}
      {orders.length > 0 && !all && (
        <div styleName="footer">
          <Button onClick={showAll}>Показать заказы за все время</Button>
        </div>
      )}
    </div>
  );
};

const EmptyState = () => <span>Здесь будут ваши заказы.</span>;
const OrdersList = ({ orders, openModal }) =>
  orders.map(o => <OrderCard openModal={openModal} data={o} key={o.entity_id} />);

MyHistoryTab.propTypes = {
  openModal: PropTypes.func,
};

MyHistoryTab.defaultProps = {
  openModal: undefined,
};

export default MyHistoryTab;
