import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Icon.module.css';

const Icon = ({ src, size, className, ...props }) => (
  <i style={{ backgroundImage: `url(${src})` }} className={cx(className, s.root, s[`size${size}`])} {...props} />
);

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: undefined,
  size: 16,
};

export const make = path => props => <Icon src={path} {...props} />;

export default Icon;
