import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { connect } from './hocs';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    console.error(error, errorInfo);

    Sentry.withScope(scope => {
      scope.setExtras({
        ...this.props.globalState,
        errorInfo,
      });

      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      if (process.env.NOD_ENV === 'production') {
        return <div>Произошла ошибка. Мы скоро все исправим, попробуйте зайти позже.</div>;
      }

      return <div>{this.state.error.toString()}</div>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  globalState: PropTypes.object.isRequired,
};

const selector = globalState => ({ globalState });
const enhance = connect(selector);

export default enhance(ErrorBoundary);
