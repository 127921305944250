import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { formatPrice } from 'utils/helper';
import ModalLink from 'components/ModalLink';
import { selectors } from 'store';
import { connect } from './hocs';

import './MiniCart.module.css';

const MiniCart = ({ itemCount, itemSum, className }) => (
  <ModalLink id="mini-cart" component="button" to="cart" type="button" styleName="root" className={className}>
    <StaticQuery
      query={graphql`
        query MiniCardGiftQuery {
          magento {
            cachedCatalog {
              giftProducts {
                entity_id
              }
            }
          }
        }
      `}
      render={data => {
        if (data.magento.cachedCatalog.giftProducts.length === 0) {
          return null;
        }

        return <div styleName="dot" />;
      }}
    />
    {itemSum > 0 && <div styleName="sum">{`${formatPrice(itemSum)}\xa0руб`}</div>}
    <div styleName="icon">
      <span styleName="quantity">{itemCount}</span>
    </div>
  </ModalLink>
);

MiniCart.propTypes = {
  itemCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  itemSum: PropTypes.number.isRequired,
};

MiniCart.defaultProps = {
  className: undefined,
};

const selector = state => ({
  itemCount: state.cart.addedIds.length,
  itemSum: selectors.getItemSum(state),
});

const enhance = connect(selector);

export default enhance(MiniCart);
