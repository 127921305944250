import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import gray from './arrow-gray.svg';
import orange from './arrow-orange.svg';
import white from './arrow-white.svg';
import miniOrange from './arrow-mini-orange.svg';
import miniWhite from './arrow-mini-white.svg';

const colors = { gray, orange, white, 'mini-orange': miniOrange, 'mini-white': miniWhite };

const ArrowIcon = props => <Icon src={colors[props.color]} {...props} />;

ArrowIcon.propTypes = {
  color: PropTypes.string,
};
ArrowIcon.defaultProps = {
  color: 'gray',
};

export default ArrowIcon;
