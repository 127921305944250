import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { analytics } from 'components/Analytics';
import { ModalContext } from 'components/ModalRenderer';

const ModalLink = ({ to, component, params, onClick, single, ...props }) => {
  const { openModal, setQuery } = useContext(ModalContext);

  return React.createElement(component, {
    onClick: e => {
      if (onClick) onClick(e);

      if (single) {
        analytics.openModal(to);
        setQuery({ [`${to}Modal`]: params });
        return;
      }

      openModal(to, params);
    },
    ...props,
  });
};

ModalLink.propTypes = {
  to: PropTypes.string.isRequired,
  single: PropTypes.bool,
  params: PropTypes.object,
  component: PropTypes.any,
  onClick: PropTypes.func,
};

ModalLink.defaultProps = {
  single: false,
  component: 'span',
  params: null,
  onClick: undefined,
};

export default ModalLink;
