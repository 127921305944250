import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Link } from 'gatsby';
import cn from 'classnames';
import Input from './Input';
import PhoneInput from './PhoneInput';
import PaymentMethod, { paymentMethods } from './PaymentMethod';
import DeliveryTimeInput from './DeliveryTimeInput';
import Checkbox from './Checkbox';
import Button from './Button';
import ArrowIcon from './icons/Arrow';
import HintDot from './HintDot';
import './CheckoutForm.module.css';

const normalizePhone = v => v.replace(/[^0-9]/g, '').replace(/^7/, '');
const validateAddress = value => (value && value.length > 6 ? undefined : 'Введите адрес доставки');
const validateName = value => (value && value.length > 1 ? undefined : 'Введите ваше имя');
const validatePhone = value => (value && normalizePhone(value).length === 10 ? undefined : 'Введите номер телефона');

const ecoText = `
  Вы можете выбрать эко-упаковку продуктов, в которой предпочтение отдается биоразлагаемым и перерабатываемым
  материалам (бумага, картон, стекло), а использование пластика сводится к минимуму. Эко-упаковка не наносит
  вред окружающей среде, но увеличивает риск возможного повреждения продуктов при транспортировке.
`;

const CheckoutForm = ({ asapVisible, onClose, deliveryIntervals, ...props }) => (
  <Form
    {...props}
    render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit} styleName="content">
        <Input
          placeholder="Адрес доставки"
          label="Адрес доставки"
          name="address"
          validate={validateAddress}
          styleName="input"
        />
        <Input
          placeholder="Комментарий к заказу / промокод"
          label="Комментарий к заказу / промокод"
          name="comment"
          component="textarea"
          styleName="input"
        />

        <Field name="payment_method">
          {({ input }) => (
            <div styleName="switcher-wrapper">
              {paymentMethods.map(method => (
                <PaymentMethod
                  styleName="payment-method"
                  key={method}
                  type={method}
                  checked={method === input.value}
                  onClick={() => input.onChange(method)}
                />
              ))}
            </div>
          )}
        </Field>
        <Field name="dateTime">
          {({ input }) => <DeliveryTimeInput {...input} deliveryIntervals={deliveryIntervals} />}
        </Field>
        <div styleName="row">
          <div styleName="cell">
            <Field name="eco_pack" type="checkbox">
              {({ input }) => (
                <Checkbox id="ecoPack" styleName="asap-checkbox" {...input}>
                  Эко упаковка <HintDot data-rh-at="top">{ecoText}</HintDot>
                </Checkbox>
              )}
            </Field>
          </div>
        </div>
        <div styleName="row">
          <div styleName="cell">
            <Input placeholder="Как к вам обращаться?" validate={validateName} name="name" />
          </div>
          <div styleName="cell">
            <PhoneInput name="phone" validate={validatePhone} placeholder="Номер телефона" />
          </div>
        </div>

        <Field name="accept" type="checkbox" validate={v => !v}>
          {({ input, meta }) => (
            <Checkbox id="accept" styleName="offer" {...input}>
              Принимаю условия{' '}
              <Link onClick={onClose} styleName="offer-link" to="/privacy-policy-cookie-restriction-mode">
                договора-оферты
              </Link>
              {meta.touched && (meta.error || meta.submitError) && (
                <div styleName="offer-error">Для отправки заказа нужно принять условия оферты</div>
              )}
            </Checkbox>
          )}
        </Field>

        <div styleName="footer">
          <Field name="payment_method" subscription={{ value: true }}>
            {({ input }) => (
              <Button styleName="button" type="submit" disabled={submitting}>
                {input.value === 'online' ? 'Отправить заказ и перейти к оплате' : 'Оформить заказ'}
                <span styleName={cn('arrow-wrapper', { disabled: submitting })}>
                  <ArrowIcon styleName="button-arrow" color="mini-white" />
                  <ArrowIcon styleName="button-arrow" color="mini-orange" />
                </span>
              </Button>
            )}
          </Field>
        </div>
      </form>
    )}
  />
);

CheckoutForm.propTypes = {
  asapVisible: PropTypes.bool.isRequired,
  deliveryIntervals: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckoutForm;
