export const getPriceProduct = (qty, price) => qty * price;

export const getWeight = (weight, freshType) => {
  let weightValue = weight;

  if (freshType !== 'кг') {
    weightValue = 1;
  }

  if (freshType === 'гр') {
    weightValue = weight * 1000;
  }

  return Number(weightValue);
};

export const getQtyValue = (qty, weight, freshType) => Number((qty * getWeight(weight, freshType)).toFixed(2));

export const formatPrice = value => `${+value}.`.replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', '');

export const getRowsDropDown = obj => {
  const rows = [];
  const maxCount = 20;
  const countRows = Math.min(Number(obj.warehouse) || maxCount, maxCount);
  const weight = getWeight(obj.weight, obj.freshType, obj.step);
  const start = Number(obj.start) || 1;
  /* eslint-disable no-plusplus */
  for (let i = start; i < countRows + 1; i++) {
    rows.push(Math.round(i * weight * 100) / 100);
  }

  return rows;
};

export const get2Digit = value => (value < 10 && value >= 0 ? `0${value}` : value);

const toMinutes = v => {
  const m = v.match(/(\d+):(\d+)/);
  return m ? parseInt(m[1], 10) * 60 + parseInt(m[2], 10) : 0;
};

/* eslint-disable no-unused-vars */
export const getAvailableTimeOptions = (isToday, date) => {
  const now = toMinutes(date);
  const delay = 2;
  const start = 8;

  const ranges = [
    {
      from: 8,
      to: 12,
      prepareTime: 8,
    },
    {
      from: 10,
      to: 13,
      prepareTime: 9,
    },
    {
      from: 12,
      to: 15,
      prepareTime: 4,
    },
    {
      from: 14,
      to: 17,
    },
    {
      from: 16,
      to: 19,
    },
    {
      from: 18,
      to: 21,
    },
    {
      from: 20,
      to: 23,
      prepareTime: 3,
    },
    {
      from: 21,
      to: 24,
      prepareTime: 3,
    },
  ];

  return ranges.map((item, i) => ({
    disabled: isToday, // && (item.from - (item.prepareTime || 5)) * 60 < now,
    value: `${item.from}:00:00`,
    label: `${get2Digit(item.from)}:00-${get2Digit(item.to % 24)}:00`,
  }));
};

export const isEmpty = obj => Object.keys(obj).length === 0;
