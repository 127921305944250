import React from 'react';
import PropTypes from 'prop-types';
import { selectors } from 'store';
import { useGlobal } from './hooks';

import './Phone.module.css';

const Phone = ({ className, ...props }) => {
  const [visible] = useGlobal(selectors.getPhoneVisible);

  if (!visible) {
    return null;
  }

  return (
    <a href="tel:+74951201841" className={className} styleName="root" {...props}>
      8 (495) 120-18-41
    </a>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
};

Phone.defaultProps = {
  className: undefined,
};

export default Phone;
