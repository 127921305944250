import React from 'react';
import PropTypes from 'prop-types';
import Close from 'components/icons/Close';
import './ModalWindow.module.css';

const ModalWindow = ({ onClose, children, withoutClose, className, id, style }) => (
  <div id={id} role="presentation" styleName="overlay" onClick={e => e.target === e.currentTarget && onClose()}>
    <div styleName="root" className={className} style={style}>
      {!withoutClose && <Close styleName="close" role="presentation" onClick={onClose} />}
      {children}
    </div>
  </div>
);

ModalWindow.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  withoutClose: PropTypes.bool,
  style: PropTypes.object,
  id: PropTypes.string,
};

ModalWindow.defaultProps = {
  className: undefined,
  style: undefined,
  id: undefined,
  withoutClose: false,
};

export default ModalWindow;
