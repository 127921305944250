import 'isomorphic-fetch';
import React, { useMemo } from 'react';
import { Provider, createClient } from 'urql';
import * as Sentry from '@sentry/browser';

import { remove } from 'unchanged';
import createRetry from 'utils/fetchRetry';
import { useGlobal } from './hooks';

const fetchWithRetry = createRetry(fetch);

const buildClient = (token, updateToken) => {
  const fetchWithResetToken = (url, opts) =>
    fetchWithRetry(url, opts)
      .then(res => {
        if (res.status === 401 && token) {
          // eslint-disable-next-line
          console.log('reset token');
          updateToken(null);
          return fetchWithRetry(url, remove('headers.Authorization', opts));
        }

        return res;
      })
      .catch(err => {
        Sentry.withScope(scope => {
          scope.setExtras({ url, opts });
          Sentry.captureException(err);
        });

        throw err;
      });

  let fetchOptions;

  if (token) {
    fetchOptions = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return createClient({
    url: '/graphql',
    fetch: fetchWithResetToken,
    fetchOptions,
  });
};

const ClientProvider = props => {
  const [token, updateToken] = useGlobal(
    state => state.token,
    actions => actions.updateToken
  );

  const client = useMemo(() => buildClient(token, updateToken), [token, updateToken]);

  return <Provider value={client} {...props} />;
};

export default ClientProvider;
