import { remove } from 'unchanged';

// for selectors
import { capitalize } from 'utils/strings';
import addDays from 'date-fns/add_days';
import formatDate from 'date-fns/format';
import locale from 'date-fns/locale/ru';

/*
 * Initial State
 */

export const initialState = {
  token: null,
  viewer: null,
  enabledFeatures: [],
  cart: {
    products: [],
    addedIds: [],
    quantityById: {},
  },
};

/*
 * Cart Actions
 */

const addUniq = (items, value) => {
  if (items.indexOf(value) !== -1) {
    return items;
  }

  return [...items, value];
};

const changeCartQuantity = (state, id, value) => ({
  products: state.products,
  addedIds: addUniq(state.addedIds, id),
  quantityById: {
    ...state.quantityById,
    [id]: value,
  },
});

const removeCartItem = (state, id) => ({
  quantityById: remove(id, state.quantityById),
  addedIds: state.addedIds.filter(addedId => addedId !== id),
  products: state.products.filter(b => b.entity_id !== id),
});

const initialCartState = {
  products: [],
  quantityById: {},
  addedIds: [],
};

export const getRestoredState = (data, passedState) => {
  const state = passedState || {
    token: null,
    cart: initialCartState,
    enabledFeatures: [],
  };

  if (data.token) {
    state.token = data.token;
  }

  if (data.cart) {
    const addedIds = Object.keys(data.cart).map(Number);
    const quantityById = {};

    // у одного из клиентов сплыл ид без количества.
    addedIds.forEach(id => {
      quantityById[id] = data.cart[id] || 1;
    });

    state.cart = {
      quantityById,
      addedIds,
      products: state.cart.products.filter(b => addedIds.indexOf(b.entity_id) > -1),
    };
  }

  return state;
};

const getServerState = (data, state) => {
  // api вернет null на каждый левый id
  const products = data.cartProducts.filter(Boolean);
  const quantityById = {};

  products.forEach(p => {
    quantityById[p.entity_id] = state.cart.quantityById[p.entity_id];
  });

  return {
    enabledFeatures: data.enabledFeatures,
    settings: data.settings && data.settings.businessHours,
    viewer: (data.session && data.session.isLoggedIn && data.session.customer) || null,
    cart: {
      quantityById,
      addedIds: products.map(p => p.entity_id),
      products,
    },
  };
};

const cartActions = {
  add: (store, data, exactQuantity) => {
    const quantity = store.state.cart.quantityById[data.entity_id] || 0;
    const newQuantity = exactQuantity === undefined ? quantity + 1 : exactQuantity;

    store.setState({
      cart: changeCartQuantity(store.state.cart, data.entity_id, newQuantity),
    });
  },
  addMultiple: (store, items) => {
    const cart = items.reduce((state, item) => {
      if (item.item_id) {
        return changeCartQuantity(state, item.item_id, item.qty_ordered);
      }

      return state;
    }, store.state.cart);

    store.setState({
      cart,
    });
  },
  remove: (store, data, exactQuantity) => {
    const quantity = store.state.cart.quantityById[data.entity_id] || 0;
    const newQuantity = exactQuantity === undefined ? quantity - 1 : exactQuantity;

    if (newQuantity <= 0) {
      store.setState({
        cart: removeCartItem(store.state.cart, data.entity_id),
      });
    } else {
      store.setState({
        cart: changeCartQuantity(store.state.cart, data.entity_id, newQuantity),
      });
    }
  },
  clear: store => {
    store.setState({
      cart: initialCartState,
    });
  },
};

/*
 * Viewer Actions
 */

export const actions = {
  cart: cartActions,
  signIn: (store, { customer, SID, isLoggedIn }) => {
    if (isLoggedIn) {
      store.setState({
        viewer: customer,
        token: SID,
      });
    } else {
      store.setState({
        viewer: null,
        token: SID,
      });
    }
  },
  updateToken: (store, token) => {
    store.setState({
      token,
      viewer: null, // нужно перезагрузить, если есть токен
    });
  },
  updateServerData: (store, data) => {
    store.setState(getServerState(data, store.state));
  },
  restore: (store, data) => {
    store.setState(getRestoredState(data, store.state));
  },
};

/*
 * Selectors
 */

const toMinutes = v => {
  const m = v.match(/(\d+):(\d+)/);
  return m ? parseInt(m[1], 10) * 60 + parseInt(m[2], 10) : 0;
};

const timeBetween = (v, from, to) => {
  const m = toMinutes(v);
  return toMinutes(from) <= m && m < toMinutes(to);
};

const getProductPrice = p => p.finalPrice || p.price || 1;

export const selectors = {
  getViewer: state => state.viewer,
  getPhoneVisible: state => (state.settings ? timeBetween(state.settings.todayTime, '9:00', '21:00') : true),
  getItemSum: state => {
    const addProductPrice = (sum, p) => sum + getProductPrice(p) * state.cart.quantityById[p.entity_id];
    return state.cart.products.reduce(addProductPrice, 0);
  },
  getDeliveryCost: state => {
    const itemSum = selectors.getItemSum(state);
    if (itemSum >= 5000) return 0;
    return 290;
    // if (itemSum > 5000) return 0;
    // if (itemSum > 2500) return 140;
    // return 290;
  },
  getTotalSum: state => {
    const itemSum = selectors.getItemSum(state);
    const deliveryCost = selectors.getDeliveryCost(state);
    return itemSum + deliveryCost;
  },
  getCartHint: state => {
    const itemSum = selectors.getItemSum(state);

    if (state.enabledFeatures.includes('ten_percent_discount_after_7000')) {
      if (itemSum >= 7000) {
        return 'Скидка 10% активирована!';
      }

      return `Соберите корзину на 7000 ₽ и активируйте скидку 10%! Осталось еще ${7000 - itemSum} ₽`;
    }

    return null;
  },
  getAsapVisible: state => {
    if (!state.settings) {
      return false;
    }

    const options = state.settings.showAsap;
    return options.enable && timeBetween(state.settings.todayTime, options.show_from, options.show_until);
  },
  getAvailableDayOptions: state => {
    if (!state.settings) {
      return [];
    }

    const today = new Date(state.settings.today);
    const time = state.settings.todayTime;

    const closestDay = toMinutes(time) >= toMinutes('20:00') ? addDays(today, 1) : today;
    const options = [];

    for (let i = 0; i < 6; i += 1) {
      const d = addDays(closestDay, i);

      options.push({
        value: formatDate(d, 'YYYY-MM-DD'),
        label: capitalize(formatDate(d, 'dd, D MMM', { locale })),
      });
    }

    return options;
  },
};
