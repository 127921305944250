import { useEffect } from 'react';
import { useMutation } from 'urql';
import nanoid from 'nanoid';
import * as storage from 'utils/storage';
import { useGlobal } from './hooks';

const mutation = `
  mutation TrackCart($input: TrackCartInput!) {
    trackCart(input: $input) {
      success
    }
  }
`;

const sidKey = 'cartid';

let sid = storage.getItem(sidKey);

if (!sid) {
  sid = nanoid();
  storage.setItem(sidKey, sid);
}

const TrackCart = () => {
  const [, executeMutation] = useMutation(mutation);
  const [cart] = useGlobal(s => s.cart);

  useEffect(() => {
    const browser = window.navigator.userAgent;
    const items = cart.addedIds.map(productId => ({
      productId,
      quantity: cart.quantityById[productId],
    }));

    const t = setTimeout(() => {
      executeMutation({
        input: { sid, items, browser },
      });
    }, 1000);

    return () => clearTimeout(t);
  }, [cart, executeMutation]);

  return null;
};

export default TrackCart;
