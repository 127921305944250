import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';
import s from './Input.module.css';

const TextField = ({ label, type, name, className, component: Component, validate, ...props }) => (
  <Field name={name} validate={validate} type={type}>
    {({ input, meta }) => (
      <div className={className} styleName="root">
        {label && <label htmlFor={name}>{label}</label>}
        <Component
          {...props}
          {...input}
          className={cn(s.input, { [s.errors]: meta.touched && meta.error })}
          id={name}
        />
        {meta.touched && (meta.error || meta.submitError) && (
          <div styleName="error">↑ {meta.error || meta.submitError}</div>
        )}
      </div>
    )}
  </Field>
);

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.any,
};

TextField.defaultProps = {
  className: undefined,
  label: undefined,
  validate: undefined,
  type: 'text',
  component: 'input',
};

export default TextField;
