// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-deposit-js": () => import("../src/pages/deposit.js" /* webpackChunkName: "component---src-pages-deposit-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mob-js": () => import("../src/pages/mob.js" /* webpackChunkName: "component---src-pages-mob-js" */),
  "component---src-pages-privacy-policy-cookie-restriction-mode-js": () => import("../src/pages/privacy-policy-cookie-restriction-mode.js" /* webpackChunkName: "component---src-pages-privacy-policy-cookie-restriction-mode-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-uslovija-dostavki-js": () => import("../src/pages/uslovija-dostavki.js" /* webpackChunkName: "component---src-pages-uslovija-dostavki-js" */),
  "component---src-pages-vegan-mob-js": () => import("../src/pages/vegan-mob.js" /* webpackChunkName: "component---src-pages-vegan-mob-js" */),
  "component---src-pages-vegan-js": () => import("../src/pages/vegan.js" /* webpackChunkName: "component---src-pages-vegan-js" */)
}

