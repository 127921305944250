import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useMutation } from 'urql';
import { useGlobal } from 'components/hooks';
import { signinSessionFragment } from 'fragments';
import Loading from 'components/Loading';

import Button from 'components/Button';

import './Auth.module.css';

const mutation = `
  ${signinSessionFragment}

  mutation($email: Email, $password: String!) {
    customer {
      login(email: $email, password: $password) {
        ...SigninSession
      }
    }
  }
`;

const retrievePasswordMutation = `
  mutation($email: Email) {
    customer {
      forgotPassword(email: $email) {
	      SID
      }
    }
  }
`;

const getSignIn = actions => actions.signIn;

const Password = ({ email, loginStatus, openModal }) => {
  const [, signIn] = useGlobal(null, getSignIn);

  const [signinRes, executeSigninMutation] = useMutation(mutation);
  const [resetRes, executeResetMutation] = useMutation(retrievePasswordMutation);

  const messages = {
    prepare: (
      <span>
        Введите пароль для <span styleName="orange">{email}</span>
      </span>
    ),
    retrievePassword: (
      <span>
        На почту <span styleName="orange">{email}</span> отправлен пароль
      </span>
    ),
    retrievePasswordErr: <span styleName="orange">Вы уже запросили пароль, попробуйте позже</span>,
    wrongPassword: <span styleName="orange">Введен неверный пароль</span>,
  };

  const setStatus = status => {
    openModal('password', { loginStatus: status, email });
  };

  const onSubmit = params =>
    executeSigninMutation(params).then(response => {
      if (response.data && response.data.customer.login) {
        signIn(response.data.customer.login);
        openModal('my');
        return null;
      }

      // eslint-disable-next-line no-alert
      alert('Введен неверный пароль');

      return setStatus('wrongPassword');
    });

  const retrievePass = () =>
    executeResetMutation({ email }).then(response => {
      if (response.data && response.data.customer.forgotPassword) {
        return setStatus('retrievePassword');
      }
      return setStatus('retrievePasswordErr');
    });

  const back = () => openModal('login');

  return (
    <div styleName="root">
      {signinRes.fetching || (resetRes.fetching && <Loading />)}
      <button onClick={back} type="button" styleName="logout">
        <div styleName="logout-icon" />
        Другой логин
      </button>
      <Form
        onSubmit={p => onSubmit({ password: p.password, email })}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} styleName="form">
            <div styleName="title">Личный кабинет</div>
            <div styleName="sub-title">{loginStatus ? messages[loginStatus] : messages.prepare}</div>
            <fieldset styleName="fieldset">
              <Field name="password">
                {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                {({ input }) => <input styleName="input" type="password" placeholder="Пароль" autoFocus {...input} />}
              </Field>
            </fieldset>

            <Button type="submit" styleName="button" color="light" disabled={submitting}>
              Войти
            </Button>
            <Button onClick={retrievePass} type="button" styleName="button">
              Отправить пароль на почту повторно
            </Button>
          </form>
        )}
      />
    </div>
  );
};

Password.propTypes = {
  openModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  loginStatus: PropTypes.string,
};

Password.defaultProps = {
  loginStatus: undefined,
};

export default Password;
