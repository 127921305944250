import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useMutation } from 'urql';
import Phone from 'components/Phone';
import { selectors } from 'store';
import { useGlobal } from 'components/hooks';
import { withModal } from 'components/hocs';
import IconOk from 'components/icons/Ok';
import IconPear from 'components/icons/Pear';
import Input from 'components/Input';
import Button from 'components/Button';
import { validateMail } from 'utils/validators';

import './Finish.module.css';

const mutation = `
  mutation($email: Email, $orderId: Int) {
    customer{
      signUpAfterOrder(email: $email, orderId: $orderId) {
        SID
      }
    }
  }
`;

const FinishModal = ({ onClose, mob, incrementId, paymentMethod, openModal, id }) => {
  const [viewer] = useGlobal(selectors.getViewer);
  const [phoneVisible] = useGlobal(selectors.getPhoneVisible);
  const [, executeMutation] = useMutation(mutation);

  const onSubmit = values => {
    executeMutation({
      email: values.email,
      orderId: incrementId,
    }).then(() => {
      openModal('password', { email: values.email });
    });
  };

  const paymentUrl = `https://moscowfresh.ru/pay/${id}`;

  useEffect(() => {
    if (paymentMethod === 'online') {
      setTimeout(() => {
        window.location = paymentUrl;
      }, 1000);
    }
  }, [paymentMethod]);

  return (
    <div styleName="root">
      <div styleName="top" id="modal-finish-top">
        <div styleName="container">
          {mob ? <IconPear /> : <IconOk />}
          <div styleName="title">Спасибо, ваш заказ принят!</div>
          {!mob && !viewer && (
            <div styleName="text">
              Введите Email, и мы отправим информацию о вашем заказе и пароль, введя который вы сможете создавать
              похожие заказы, смотреть истрию и многое другое.
            </div>
          )}
          {!mob && incrementId && !viewer && (
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="email" validate={validateMail}>
                    {({ input, meta }) => (
                      <Fragment>
                        <div styleName="error">{meta.touched && (meta.error || meta.submitError)}</div>
                        <Input styleName="input" placeholder="Email" {...input} />
                      </Fragment>
                    )}
                  </Field>
                  <Button styleName="button" color="light" type="submit">
                    Отправить
                  </Button>
                </form>
              )}
            />
          )}
          {paymentMethod === 'online' && (
            <a href={paymentUrl}>
              <Button styleName="online" color="light" type="submit">
                Перейти на страницу оплаты
              </Button>
            </a>
          )}
        </div>
      </div>
      <div styleName="footer">
        <div styleName="text">
          Стоимость по весовым позициям заказа может незначительно измениться. <br />
          {phoneVisible && (
            <>
              По любым вопросам звоните нам: <Phone styleName="phone" />
            </>
          )}
        </div>
      </div>
      {mob && (
        <Button styleName="button-mob" onClick={onClose}>
          Отлично!
        </Button>
      )}
    </div>
  );
};

FinishModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  incrementId: PropTypes.number,
  mob: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  paymentMethod: PropTypes.string,
  id: PropTypes.string,
};

FinishModal.defaultProps = {
  incrementId: undefined,
  id: undefined,
  paymentMethod: undefined,
  mob: false,
};

const enhance = withModal({
  maxWidth: 700,
});

export default enhance(FinishModal);
