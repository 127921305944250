import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useMutation } from 'urql';
import { validateMail } from 'utils/validators';
import { isEmpty } from 'utils/helper';
import Loading from 'components/Loading';
import Button from 'components/Button';

import './Auth.module.css';

const mutation = `
  mutation($email: Email) {
    customer {
      signUp(email: $email) {
        SID
      }
    }
  }
`;

const Login = ({ openModal }) => {
  const [res, executeMutation] = useMutation(mutation);

  const sendPassword = email => {
    executeMutation({ email }).then(response => {
      if (response.data && response.data.customer.signUp) {
        openModal('password', { email });
        // eslint-disable-next-line no-alert
        alert(`Новый пароль отправлен на ${email}`);
        return;
      }

      const message =
        !response.errors || isEmpty(response.errors[0])
          ? 'Вы уже запросили пароль, попробуйте позже'
          : `Ошибка\n${JSON.stringify(response.errors)}`;

      // eslint-disable-next-line no-alert
      alert(message);
    });
  };

  const toPassword = values => openModal('password', values);

  return (
    <div styleName="root">
      {res.fetching && <Loading />}
      <Form
        onSubmit={toPassword}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} styleName="form">
            <div styleName="title">Личный кабинет</div>
            <Field name="email" validate={validateMail}>
              {({ input, meta }) => (
                <React.Fragment>
                  <div styleName="sub-title orange">
                    {(meta.touched && (meta.error || meta.submitError)) ||
                      'Авторизуйтесь, чтобы сохранялись заказы и накапливались баллы'}
                  </div>
                  <fieldset styleName="fieldset">
                    {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                    <input styleName="input" type="email" autoFocus placeholder="Email" {...input} />
                  </fieldset>
                  <Button styleName="button" color="light" type="submit">
                    У меня есть пароль
                  </Button>
                  <Button
                    onClick={() => !meta.error && sendPassword(input.value)}
                    styleName="button"
                    disabled={meta.error}
                  >
                    Отправить новый пароль на почту
                  </Button>
                </React.Fragment>
              )}
            </Field>
          </form>
        )}
      />
    </div>
  );
};

Login.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default Login;
